h1 {
	margin-top: 20vh;
	margin-bottom: 20vh;
}

.App-header {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}
