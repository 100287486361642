.delete-modal-container,
.one-selected-container,
.many-selected-container {
  padding: 20px;
}

.many-selected-container {
  .content {
    padding-inline-start: 20px;
    margin: 18px 0 20px 0 !important;
  }
  .company {
    height: 18px;
  }
}
