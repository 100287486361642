.content {
  height: 100%;
  padding: 24px;
  padding-bottom: 16px;

  .body-order-content {
    padding: 0 !important;
    width: calc(100% - 283px) !important;

    .divider-horizontal {
      background-color: var(--color-background-04);
      height: 1px;
      width: 100%;
      margin: 16px 0;
    }
  }

  .body-content {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    .divider {
      background-color: var(--color-background-04);
      height: 100%;
      width: 1px;
      margin: 0 16px;
    }

    .right-menu-content {
      width: 250px !important;
    }
  }

  .loadingPage {
    margin-left: 45%;
    height: 95%;
  }
}
