.container {
  display: flex;
  justify-content: flex-end;
  .content {
    margin-bottom: 10px;
    .description {
      margin-bottom: 5px;
      justify-content: end;
    }
    .left-icon {
      margin-right: 4px;
    }

    .div-children {
      justify-content: flex-end;
    }
  }
}
