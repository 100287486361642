.footer-button {
  display: flex;
  gap: 10px;
}

.avatar-list {
  display: flex;

  > div:first-child {
    margin-right: 10px;
  }
}

.radio-button {
  display: flex !important;

  margin-bottom: 30px !important;
  margin-top: 20px !important;

  > div:first-child {
    margin-right: 25px;
  }
  > div {
    margin-bottom: 0 !important;
  }
  > span > div {
    margin-bottom: 0 !important;
  }
}
