.header {
  padding: 16px;
  background: var(--color-background-01);

  border-radius: 8px 8px 0 0;

  border: 1px solid var(--color-background-04);
  border-bottom: none;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attendant-avatar {
  margin-right: 4px !important;
}
