.aprove-button {
  margin-left: 6px;
  margin-right: 4px;
}

.component-static {
  height: 36px;
  width: 100%;

  background-color: var(--color-status-disabled-t15);
  border: 1px solid var(--color-status-disabled-t45);

  border-radius: 8px;

  align-items: center;
  justify-content: space-between;

  > div:first-child {
    height: 16px;
  }

  .left-Icon {
    margin: 0 12px 0 12px;
  }

  .right-icon > div {
    margin-right: 12px;
  }

  .tag-qsp {
    margin-left: 6px;
  }
}
.formula-component {
  margin-top: 8px;
  width: calc(100% + 140px);

  .formula-component-sub-item {
    width: 12px;
    height: 16px;

    border-radius: 0 0 0 6px;
    border-left: 2px solid var(--color-text-400);
    border-bottom: 2px solid var(--color-text-400);

    margin: 3px 3px 17px 9px;
  }
}

.hide {
  transition: opacity 400ms;

  opacity: 0;
}
.hide-display {
  display: none !important;
}

.component-static {
  height: 36px;
  width: 100%;

  background-color: var(--color-status-disabled-t15);
  border: 1px solid var(--color-status-disabled-t45);

  border-radius: 8px;

  align-items: center;
  justify-content: space-between;

  > div:first-child {
    height: 16px;
  }

  .left-Icon {
    margin: 0 12px 0 12px;
  }

  .right-icon > i {
    margin-right: 12px;
  }

  .tag-qsp {
    margin-left: 6px;
  }
}
