.footer-button {
  display: flex;
  gap: 10px;
}

.checkbox {
  > div {
    background-color: var(--color-white);
    padding: 14px 20px;
    border-radius: 4px;
    margin: 12px 0 0 8px !important;
  }
}
