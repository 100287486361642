.dropdown {
  width: 100%;

  .icon-with-tooltip {
    margin-left: 5px;
  }

  &:not(.margin-bottom) {
    margin-bottom: 0px;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .div-dropdown {
    width: 100%;
    position: relative;
    height: 36px;

    .spinner,
    .empyt {
      height: 20px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        z-index: 99999 !important;
        margin: 0 !important;
      }
    }

    .div-flag {
      width: 28px;
      height: 22px;
      border-radius: 12px;
      border: none !important;
      background: var(--color-text-600);

      position: absolute;
      top: 7px;
      left: 12px;
      z-index: 1;
    }

    .option {
      font-family: var(--font-family) !important;
      font-style: normal !important;
      font-weight: var(--font-weight-light) !important;
      font-size: var(--font-size-xs) !important;
      background-color: var(--color-background-01);
    }

    .option-avatar {
      height: 43px !important;
      padding: 0 16px !important;
    }

    .option-disabled {
      color: var(--color-text-400) !important;
      cursor: not-allowed !important;

      &:active {
        pointer-events: none;
      }
    }

    .errorText {
      margin-top: 6px;
      font-family: var(--font-family) !important;
      font-weight: normal;
      font-size: var(--font-size-xs);
      line-height: var(--font-line-height-xxs);
      color: var(--color-status-danger-base) !important;
    }
  }

  .sub-text {
    margin-top: 5px;
  }
}
