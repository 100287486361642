.div-content,
.div-content-with-code {
  align-items: center;

  width: 100%;

  > p {
    color: var(--color-text-50) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
    max-height: 30px;
  }

  > i {
    margin-right: 13px;
  }

  .div-content-text {
    display: flex;
    flex-direction: column;
    width: 100%;

    .text-dropdown {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.code-dropdown {
  max-width: 85%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.div-content {
  display: flex;

  //selected image on dropdown
  > div:first-child > img {
    height: 22px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
  }
}

.div-content-with-code {
  display: block;
}
