.print-card-container {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid var(--color-background-04);
  transition: all ease .5s;
  background: var(--color-background-02);
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
  }
}

.print-card-container:hover, .active-card {
  border-color: var(--color-primary-base);
  background: var(--color-background-04);
}
