.buttonOk {
  min-width: 70px !important;
  border-radius: 8px !important;
}

.buttonCancel {
  width: auto !important;
  border-radius: 8px !important;
}

.footer {
  display: flex !important;

  background: var(--color-background-01) !important;

  padding: 12px 20px !important;

  justify-content: space-between !important;

  align-items: center !important;

  border-top: 1px solid var(--color-background-04) !important;

  border-radius: 0 0 10px 10px !important;
}
