.ant-collapse:not(.collapse-withoutCollapse)
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  position: unset !important;
}

.collapse-withoutCollapse {
  .ant-collapse-header {
    cursor: default !important;

    .ant-collapse-arrow {
      padding: 0;
      .icon {
        display: none !important;
      }
    }
  }
  .ant-collapse-expand-icon {
    padding-inline-end: 0px !important;
    display: flex !important;
  }
}

.ant-collapse-item-active {
  .ant-collapse-header {
    align-items: center;
  }

  .chevron-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
}

.chevron-icon {
  transition: transform 0.3s ease;
}

.ant-collapse-header {
  display: flex !important;
  align-items: center !important;

  padding: 0 !important;

  > span {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
  }
  > p {
    user-select: none;
  }
}

.ant-collapse-content-box {
  padding: 0 !important;
}
