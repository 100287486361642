.footer-button {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.radio-group {
  padding: 20px;

  > div:first-child {
    margin-right: 25px;
  }
  > div {
    margin-bottom: 0 !important;
  }
  > span > div {
    margin-bottom: 0 !important;
  }
}
