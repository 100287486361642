.no-items {
  margin-top: 0 !important;
}

.Containers {
  width: 100%;
  margin-top: 20px;

  > div:last-child {
    margin-bottom: 0 !important;
  }

  .row {
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;

    .content {
      display: flex;
      align-items: center;
    }

    .delete-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: var(--color-text-400);
        i {
          color: var(--color-white) !important;
        }
      }

      .icon {
        cursor: pointer;
      }
    }
  }
}
