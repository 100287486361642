.login-layout {
  position: relative;

  .page-login {
    display: flex;

    height: 100vh;

    justify-content: center;
    align-items: center;

    .divLogin {
      width: 428px;

      padding: 50px;
      border-radius: 8px;

      background: var(--color-background-01);

      .logo-bootys {
        text-align: center;
        margin-bottom: 40px;
      }

      .title,
      .copyright {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .title {
        margin-bottom: 40px;
      }
      .copyright {
        margin-top: 40px;
      }
    }
  }

  .backgound-image {
    position: absolute;

    top: 0;
    left: 0;

    width: 50%;
    height: 100vh;

    z-index: -1;
  }

  .background {
    position: absolute;

    background: var(--color-background-00);

    top: 0;
    right: 0;

    width: 50%;
    height: 100vh;

    z-index: -1;
  }
}
