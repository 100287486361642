#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--color-primary-base);

  position: fixed;
  z-index: 1031;
  top: 64px;
  left: 65px !important;

  width: calc(100% - 65px) !important;
  height: 2px !important;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: -2px !important;
  width: 15px !important;
  height: 100%;
  background: var(--color-primary-base) !important;
  box-shadow: 0px 0px 10px var(--color-primary-base);
  opacity: 1;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
