.Form {
  width: 100%;
  max-width: 415px;

  .email {
    color: var(--color-primary-base);
  }

  .title {
    margin-bottom: 24px;
  }

  .text {
    margin-bottom: 40px;
    > p {
      margin-bottom: 0 !important;
    }
  }

  .info-label {
    margin-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .login-link {
    text-align: center;
    font-weight: var(--font-weight-bold);
    display: flex;
    justify-content: center;
  }
}

.title {
  text-align: start !important;
  margin-bottom: 20px !important;
}
