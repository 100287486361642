.container {
  padding: 0 10px;

  background-color: var(--color-background-03);
  border-radius: 8px;
  overflow: hidden;

  transition: max-height 0.5s, height 0.5s;
  transition-delay: unset;
  > div:first-child {
    height: 36px;
    display: flex;
    align-items: center;

    width: 100%;

    justify-content: space-between;

    margin-bottom: 2px;
  }
  .title-div {
    display: flex;
    align-items: baseline;
    > div {
      margin-right: 6px;
    }

    > div:first-child,
    i {
      margin-right: 12px;
    }
  }

  .chevron-open-true {
    animation: rotate-true 0.4s normal forwards;
  }
  .chevron-open-false {
    animation: rotate-false 0.4s normal forwards;
  }
}

@keyframes rotate-true {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate-false {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
