.divContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;

  padding: 6px 12px;

  border-radius: 8px;

  margin-bottom: 20px;

  border: 1px solid var(--color-status-disabled-t45);

  background: var(--color-status-disabled-t45);

  .div-description {
    margin-left: 12px;
    display: flex;
  }

  .loading {
    margin: 0 !important;
  }
}

.without-margin-bottom {
  margin-bottom: 0px !important;
}
