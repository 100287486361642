.Form {
	width: 100%;
	max-width: 415px;

	.button {
		margin-top: 40px;
		margin-bottom: 10px;
	}

	.return-button {
		text-align: center;
	}
}
