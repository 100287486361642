.horizontal-steps {
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--color-status-success-base) !important;
    border: none !important;
  }

  .ant-steps-item-active .ant-steps-item-icon {
    background-color: var(--color-status-primary-base) !important;
    border: none !important;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent !important;
    border: 1px solid var(--color-background-04) !important;

    > span {
      color: var(--color-text-300) !important;
    }
  }

  .ant-steps-item-container {
    display: flex;
    align-items: center;

    .ant-steps-item-title {
      font-family: 'Open Sans', sans-serif !important;
      font-style: normal !important;

      font-weight: var(--font-weight-normal) !important;
      font-size: var(--font-size-xs) !important;
      line-height: var(--font-line-height-xxs) !important;

      color: var(--color-text-50) !important;

      &::after {
        display: none !important;
      }
    }
  }

  .ant-steps-item-icon {
    height: 28px !important;
    width: 28px !important;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    > span {
      color: var(--color-white) !important;
    }

    .ant-steps-icon .ant-steps-finish-icon > svg {
      height: 12px !important;
      width: 12px !important;
    }
  }

  .ant-steps-item {
    flex: none !important;
    display: flex !important;

    align-items: center;

    padding: 0 !important;

    .ant-steps-item-title {
      padding: 0 !important;
    }
  }
}

.vertical-time-line {
  .ant-steps-item-icon {
    margin-right: 0 !important;
    width: auto !important;
    .ant-steps-icon > div > div {
      display: flex;
      align-items: center;
      height: 28px;
    }
  }

  .ant-steps-item-container {
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    .ant-steps-item-tail {
      height: 25px !important;
      width: 2px !important;
      top: auto !important;

      bottom: 4px !important;
      padding: 0 !important;
      inset-inline-start: 13px !important;
    }
    .ant-steps-item-tail::after {
      width: 2px !important;
    }
    .ant-steps-item-title {
      color: var(--color-text-100) !important;
      font-size: var(--font-size-sm) !important;
      font-weight: var(--font-weight-light) !important;
      font-family: var(--font-family) !important;
      line-height: 16px !important;
      p {
        margin-bottom: 5px !important;
      }
    }
    .ant-steps-item-description {
      color: var(--color-text-300) !important;
      font-size: var(--font-size-xs) !important;
      font-weight: var(--font-weight-light) !important;
      font-family: var(--font-family) !important;
      padding-bottom: 0px !important;
    }

    .ant-steps-item-content {
      margin-left: 12px;
    }
  }

  :where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical
    > .ant-steps-item
    .ant-steps-item-title {
    line-height: 16px !important;
  }
  .ant-steps-item-content {
    min-height: auto !important;
  }

  .bold-text {
    font-weight: var(--font-weight-normal);
  }
}
