.skeleton > div {
  > h3 {
    width: 100% !important;
  }
  > ul {
    display: none;
  }
}

.size-md > div {
  > h3 {
    height: 24px !important;
  }
}

.size-lg > div {
  > h3 {
    height: 42px !important;
  }
}

.size-gg > div {
  > h3 {
    height: 45px !important;
  }
}
