.progress-bar {
  background: var(--color-background-06);
  margin-top: 12px;
  height: 7px;
  border-radius: 2px;
}

.progress-bar--success {
  height: 7px;
  background: var(--color-status-success-base);
  border-radius: 2px;
  transition: width 0.5s ease-in-out;
}
