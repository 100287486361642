.Form {
  width: 100%;
  max-width: 415px;

	.button-recovery {
		margin-top: 40px;
	}

  .return-button {
    margin-top: 10px;
    text-align: center;
  }
}