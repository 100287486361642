/*
* Create react app does not provide a way to ignore some errors,
* and because our react query always throws erros when a request status is > 200,
* we want to not display this on development mode.
*
* REFERENCE: https://stackoverflow.com/a/47400249
*/

body > iframe {
    display: none
}