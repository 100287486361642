.dropdownItem {
  cursor: pointer;
  padding: 10px 7px;
  font-weight: var(--font-weight-bold) !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: var(--font-size-xs) !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: var(--color-primary-base);

    &::after {
      content: '';
      position: absolute;
      left: -5px;
      width: 3px;
      height: 100%;
      background-color: var(--color-primary-base);
      border-radius: 0 8px 8px 0;
      z-index: 1;
    }
  }
}

.dropdownLabel {
  font-size: var(--font-size-xs) !important;
  margin: 0 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    pointer-events: none;
  }
}

.dropdownContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2px;
}

.dropdownBoxShadow {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 5px;
}

.hasNoPermission {
  cursor: not-allowed;
  color: var(--color-status-disabled--40);
}

.hasNoPermission:hover {
  &:hover {
    color: var(--color-status-disabled--40);

    &::after {
      content: '';
      color: transparent;
      background-color: transparent;
    }
  }
}

.hasNoPermission:active {
  pointer-events: none;
}
