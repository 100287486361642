html.midnightMode {
  // Primary
  --color-primary-background: rgba(126, 115, 245, 0.15);
  --color-primary-darkest: #534c9a;
  --color-primary-dark: #6960c8;
  --color-primary-base: #7e73f5;
  --color-primary-light: #988ff7;
  --color-primary-lightest: #b2abf9;

  // Constant
  --color-black: #121212;
  --color-white: #ffffff;

  // Text
  --color-text-50: #ffffff;
  --color-text-100: #cac9d4;
  --color-text-200: #9d9ca7;
  --color-text-300: #72717c;
  --color-text-400: #51505b;
  --color-text-500: #464550;
  --color-text-600: #31303b;
  --color-text-700: #262530;
  --color-text-800: #1b1a25;

  // Background
  --color-background-00: #1b1a25;
  --color-background-01: #262530;
  --color-background-02: #2c2b36;
  --color-background-03: #302f3a;
  --color-background-04: #35343f;
  --color-background-06: #3b3a45;
  --color-background-08: #3f3e49;
  --color-background-12: #44434e;
  --color-background-16: #4b4a55;
  --color-background-24: #51505b;

  // Status
  --color-status-success--40: #9dddb8;
  --color-status-success--20: #7dd1a1;
  --color-status-success-base: #5cc689;
  --color-status-success-P20: #4ea272;
  --color-status-success-P40: #3e7e59;
  --color-status-success-t45: rgba(92, 198, 137, 0.45);
  --color-status-success-t30: rgba(92, 198, 137, 0.3);
  --color-status-success-t15: rgba(92, 198, 137, 0.15);

  --color-status-warning--40: #ffd39a;
  --color-status-warning--20: #ffc578;
  --color-status-warning-base: #ffb656;
  --color-status-warning-P20: #d09649;
  --color-status-warning-P40: #a0743b;
  --color-status-warning-t45: rgba(255, 182, 86, 0.45);
  --color-status-warning-t30: rgba(255, 182, 86, 0.3);
  --color-status-warning-t15: rgba(255, 182, 86, 0.15);

  --color-status-danger--40: #f7a0a0;
  --color-status-danger--20: #f48181;
  --color-status-danger-base: #f16161;
  --color-status-danger-P20: #c55252;
  --color-status-danger-P40: #984141;
  --color-status-danger-t45: rgba(241, 97, 97, 0.45);
  --color-status-danger-t30: rgba(241, 97, 97, 0.3);
  --color-status-danger-t15: rgba(241, 97, 97, 0.15);

  --color-status-warning--40: #a7d2ec;
  --color-status-warning--20: #89c3e6;
  --color-status-information-base: #6cb4e0;
  --color-status-warning-P20: #5a94b7;
  --color-status-warning-P40: #48738d;
  --color-status-information-t45: rgba(108, 180, 224, 0.45);
  --color-status-information-t30: rgba(108, 180, 224, 0.3);
  --color-status-information-t15: rgba(108, 180, 224, 0.15);

  --color-status-default--40: #a0a0a0;
  --color-status-default--20: #d0d0d0;
  --color-status-default-base: #ffffff;
  --color-status-default-P20: #d0d0d0;
  --color-status-default-P40: #a0a0a0;
  --color-status-default-t45: rgba(255, 255, 255, 0.45);
  --color-status-default-t30: rgba(255, 255, 255, 0.3);
  --color-status-default-t15: rgba(255, 255, 255, 0.15);

  --color-status-disabled--40: #97969d;
  --color-status-disabled--20: #74737c;
  --color-status-disabled-base: #51505b;
  --color-status-disabled-P20: #45444d;
  --color-status-disabled-P40: #38373e;
  --color-status-disabled-t45: rgba(77, 77, 77, 0.45);
  --color-status-disabled-t30: rgba(77, 77, 77, 0.3);
  --color-status-disabled-t15: rgba(77, 77, 77, 0.15);

  --color-status-primary--40: #b2abf9;
  --color-status-primary--20: #988ff7;
  --color-status-primary-base: #7e73f5;
  --color-status-primary-P20: #6960c8;
  --color-status-primary-P40: #534c9a;
  --color-status-primary-t45: rgba(126, 115, 245, 0.45);
  --color-status-primary-t30: rgba(126, 115, 245, 0.3);
  --color-status-primary-t15: rgba(126, 115, 245, 0.15);

  // Users
  --color-users01: #2592cc;
  --color-users02: #1c65ae;
  --color-users03: #763dac;
  --color-users04: #cd3acd;
  --color-users05: #f65879;
  --color-users06: #ff7f35;
  --color-users07: #ffbd3e;
  --color-users08: #ffe16c;
  --color-users09: #46bc85;
  --color-users10: #26deae;
  --color-users11: #17e1de;
  --color-users12: #4ed5ff;

  // Highlight
  --color-highlight: rgba(255, 215, 13, 0.45);

  // Gradient
  --color-messenger-gradient: linear-gradient(
    225deg,
    rgba(251, 108, 103, 1) 1%,
    rgba(251, 81, 130, 1) 16%,
    rgba(166, 54, 244, 1) 56%,
    rgba(1, 150, 254, 1) 100%
  );

  --color-instagram-gradient: linear-gradient(
    225deg,
    rgba(253, 221, 136, 1) 11%,
    rgba(245, 49, 71, 1) 44%,
    rgba(151, 62, 189, 1) 76%,
    rgba(123, 72, 235, 1) 100%
  );
}
