.ant-modal-content {
  border-radius: 10px !important;
  background: var(--color-background-01) !important;
  padding: 0 !important;
  margin: auto;
}

.ant-modal-body {
  padding: 0 !important;
  border-top: none;
  border-radius: 0 0 10px 10px !important;

  height: calc(100% - 56.8px);
}

.ant-modal-header {
  border-radius: 10px 10px 0 0 !important;
  border: none;
  background: var(--color-background-01) !important;
  border-bottom: 1px solid var(--color-background-04) !important;
  padding: 16px 55px 16px 20px !important;
  margin-bottom: 0 !important;
}

.ant-modal-footer {
  display: none !important;
  padding: 0 !important;
}

.ant-modal-wrap {
  display: flex;
}

.ant-modal {
  margin: auto !important;
  top: 0 !important;
}

.ant-modal-close-x {
  display: flex !important;
  font-size: var(--font-size-l) !important;
}

.ant-modal-close {
  display: flex !important;
  font-size: var(--font-size-l) !important;
  cursor: default !important;

  width: 24px !important;
  height: 24px !important;
}

.ant-modal-title {
  font-family: Open Sans !important;
  font-style: normal !important;

  color: var(--color-text-50) !important;
  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-md) !important;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1034 !important;
}

.ant-modal-confirm-title {
  padding: 16px 55px 16px 20px;
  border-bottom: 1px solid var(--color-background-04) !important;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  max-width: 100% !important;
  margin-block-start: 0 !important;
}
