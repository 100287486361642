.highlighted-text {
  color: var(--color-text-50) !important;
  font-weight: var(--font-weight-normal) !important;
}

.info-description {
  align-items: center;

  padding-bottom: 10px;

  > i {
    margin-right: 6px;
  }

  > div > p {
    margin: 0 !important;
  }
}
