.container-components {
  height: calc(100% - 149px);

  .horizontal-divider,
  .horizontal-divider-bottom {
    width: 100%;
    height: 1px;

    background-color: var(--color-background-04);
  }

  .horizontal-divider {
    margin-bottom: 12px;
  }
  .horizontal-divider-bottom {
    margin-bottom: 6px;
  }

  .header-components {
    width: calc(100% - 76px); // -76 refers to action buttons
    margin-bottom: 6px;

    margin-left: -20px;
  }

  .component-line {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 14px);

    width: calc(100% + 32px);

    padding-right: 8px;
    margin-right: 8px;

    margin-left: -20px;

    > li {
      display: flex;
    }
  }
}
