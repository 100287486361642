.container {
  border-radius: 4px;
  background-color: var(--color-background-04);
  width: fit-content;
  align-items: center;

  cursor: pointer;

  .status-tag {
    padding: 0 8px;
    height: fit-content;
    align-items: center;

    .channel-icon {
      margin-right: 5px;
    }

    .external-link-icon-tag {
      display: none;
      margin-left: 5px;
    }

    &:hover {
      .external-link-icon-tag {
        display: flex !important;
      }
    }
  }

  &:hover .status-tag {
    .external-link-icon-tag {
      display: flex !important;
    }
  }

  .status {
    width: 4px;
    height: 26px;
    border-radius: 4px 0 0 4px;
  }
}
