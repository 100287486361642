.filter-title {
  padding: 16px 16px 8px 12px;
  border-radius: 8px 8px 0 0;
  border: 1px solid var(--color-background-04);
  border-bottom: none !important;
}

.filter-container {
  padding-bottom: 8px;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--color-background-04);
  border-top: none !important;
  .filter-content {
    padding: 6px 16px;
    &:hover {
      background: var(--color-background-03);
      cursor: pointer;
    }
  }
}

.Icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
