.div-leftIcon {
  margin-right: 8px;
  border: none !important;
}

.div-noPadding {
  margin: 0px !important;
}

.spinner {
  margin: auto;
}
