:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.table-sale-order {
  .ant-empty {
    display: none !important;
  }
  .ant-table-thead {
    height: 16px !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
    border-left: none !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
    border-right: none !important;
  }
}

#table-item {
  .ant-table-selection {
    display: none !important;
  }

  .ant-table-fixed {
    table-layout: fixed !important;
  }

  .ant-table-thead {
    > tr > th:first-child {
      padding: 0 !important;
    }
    td.ant-table-cell,
    th.ant-table-cell {
      background: transparent !important;
      border-bottom: 1px solid var(--color-background-04) !important;
    }
  }

  .ant-table-tbody {
    > tr > td {
      word-wrap: break-word !important;
      word-break: break-all !important;
    }

    > tr > td:first-child {
      padding-left: 0 !important;
    }

    .ant-table-row-selected .ant-table-cell {
      background: none !important;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 0 6px 6px 6px !important;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 0 6px !important;
    }
  }
}
