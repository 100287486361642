.content-avatar {
  display: flex;
  width: 100%;

  align-items: center;
  > p {
    color: var(--color-text-50) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
  }

  > i {
    margin-right: 13px;
  }

  .div-avatar {
    margin-left: 12px;
    width: 100%;

    .text {
      max-width: 95%;
      display: block;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
