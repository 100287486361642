.container {
  justify-content: space-between;
  .description {
    margin: 0 8px;

    > div {
      height: 16px;
    }
  }

  .link-icon {
    display: flex;

    height: 32px;
    width: 32px;

    align-items: center;
    justify-content: center;
  }
}
