.headerItem {
  font-size: 15px;
  font-weight: bold;

  span {
    color: var(--color-status-danger-base);
  }
}

.headerRow {
  margin-bottom: 12px;
}
