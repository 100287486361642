.theme-mode {
  display: flex;

  border-radius: 4px;

  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.loading {
  cursor: not-allowed !important;
}

.check {
  position: absolute;
}

.border-check {
  border: 2px solid var(--color-primary-base);
}

.border-check.darkMode-mode {
  background: #333333;
}
.border-check.midnightMode-mode {
  background: #333333;
}
.border-check.ligthMode-mode {
  background: #ffffff;
}

.divMode {
  width: 174px;
  height: 92px;

  border-radius: 2px;
}

.div-lightMode {
  margin-right: 20px;
}

.div-midnightMode {
  margin-right: 20px;
}
