.container {
  width: 100%;
  height: 52px;

  padding: 0 12px;

  display: flex;
  align-items: center;

  background: var(--color-background-02);

  border: 1px solid var(--color-background-04) !important;
  border-radius: 8px;

  > div:nth-child(n + 2)::before {
    font-family: 'icomoon', sans-serif !important;
    content: '\e900';
    color: var(--color-text-400);
    font-size: var(--font-size-xxs);

    height: 16px;
    width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 12px !important;
  }
}
