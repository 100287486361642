form .ant-input:focus {
  border-right-width: 2px !important;
}

.ant-input {
  font-family: var(--font-family) !important;
  font-size: var(--font-size-xs) !important;
  font-weight: var(--font-weight-light) !important;
}

.ant-input-group-wrapper {
  height: 34px;

  .ant-input-group-addon {
    height: 34px;
    background: transparent !important;
    border: none !important;
    width: 36px;
  }

  input {
    height: 34px !important;
    background-color: var(--color-background-01);
    box-shadow: none !important;
    border: none !important;
    border-radius: 8px !important;

    font-family: var(--font-family) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--font-line-height-xxs) !important;
    font-weight: normal;
    color: var(--color-text-50) !important;

    &::placeholder {
      color: var(--color-text-400) !important;
    }

    &:focus {
      border: none !important;
    }
  }

  .ant-select-selector {
    padding-right: 6px !important;
    padding-left: 6px !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  
  .ant-select-open {
    outline: none !important;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  &:focus-within {
    border-radius: 8px !important;
    border: 1px solid var(--color-primary-base) !important;
  }
}

.number-input-dropdown-right {
  outline: none !important;
  border-left: 1px solid var(--color-background-04) !important;
  box-shadow: none !important;
  border-radius: 0 8px 8px 0 !important;

  > div span {
    font-family: 'Open Sans', sans-serif !important;
    color: var(--color-text-300) !important;
  }
}
