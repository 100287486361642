.divDetails {
  width: 430px;

  margin-top: 25px;

  .divAvatar {
    display: flex;

    align-items: center;

    margin-bottom: 25px;

    .button-avatar {
      margin-left: 20px;
      height: 31px !important;
    }
  }
  .divName {
    display: flex;

    width: 100%;

    .name {
      width: 100%;
      margin-right: 25px;
    }

    .last-name {
      width: 100%;
    }
  }
  .buttons {
    display: flex;
    .save-button {
      margin-right: 10px;
    }
  }

  .input {
    margin-bottom: 5px;
  }
}
