:where(.css-dev-only-do-not-override-yp8pcc).ant-notification
  .ant-notification-notice
  .ant-notification-notice-icon-error.anticon {
  display: none !important;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-notification
  .ant-notification-notice
  .ant-notification-notice-icon-error.anticon {
  display: none !important;
}

.iconToast {
  position: absolute !important;
  background: var(--color-status-danger-base) !important;
  top: 20px !important;
  right: 20px !important;

  width: 30px !important;
  height: 30px !important;

  display: flex !important;

  border-radius: 100% !important;

  font-size: var(--font-size-l) !important;

  cursor: pointer;
  z-index: 1 !important;
  > i {
    margin: auto !important;
    color: var(--color-white) !important;
  }
}

.iconToast:hover {
  opacity: 0.5 !important;
}
