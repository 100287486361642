.primary-tag:not(.disabled-tag) {
  background: var(--color-primary-base) !important;
  color: var(--color-white) !important;

  .icon-x-tag {
    color: var(--color-white) !important;
  }

  &:hover {
    background: var(--color-primary-dark) !important;
  }
}
.secondary-tag:not(.disabled-tag) {
  background: var(--color-text-600) !important;

  .icon-x-tag {
    color: var(--color-text-50) !important;
  }
  &:hover {
    background: var(--color-text-500) !important;
  }
}
.tertiary-tag:not(.disabled-tag) {
  color: var(--color-primary-base);
  background: transparent !important;
  border: 1px solid var(--color-primary-base);

  .icon-x-tag {
    color: var(--color-primary-base) !important;
  }

  &:hover {
    background: var(--color-primary-t15) !important;
    color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
    .icon-x-tag {
      color: var(--color-primary-dark) !important;
    }
  }
}

.primary-tag,
.secondary-tag {
  border: none !important;
}

.primary-tag.disabled-tag,
.secondary-tag.disabled-tag {
  border: none !important;
  background: var(--color-status-disabled-t15) !important;
  color: var(--color-status-disabled-base) !important;
  .icon-x-tag {
    color: var(--color-status-disabled-base) !important;
  }
}
.tertiary-tag.disabled-tag {
  border: 1px solid var(--color-status-disabled-base);
  background-color: transparent !important;
  color: var(--color-status-disabled-base);

  .icon-x-tag {
    color: var(--color-status-disabled-base) !important;
  }
}

.disabled-tag {
  cursor: not-allowed;
  .icon-x-tag {
    cursor: not-allowed !important;
  }
}

.Tag-with-close-x {
  padding-left: 5px;
  padding-right: 2px !important;
}

.Tag-without-close-x {
  padding: 0 5px;
}

.icon-x-tag {
  margin: 0 2px !important;
}
