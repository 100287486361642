.conference-button-default {
  background: var(--color-background-01);
  border: 1px solid var(--color-background-04);
  width: 28px;
  height: 28px;
  border-radius: 6px;
  padding: 6px 9px;
  transition: all 0.3s ease;
}

.conference-button-clickable {
  cursor: pointer;

  &:hover {
    background: var(--color-background-04);
  }
}

.correct-active {
  background: var(--color-status-success-base);

  &:hover {
    background: var(--color-status-success-base);
  }
}

.incorrect-active {
  background: var(--color-status-danger-base);

  &:hover {
    background: var(--color-status-danger-base);
  }
}

.icon-customized {
  cursor: auto !important;
}
