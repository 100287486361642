.adder-list > div > div > ul > li {
  border-block-end: none !important;

  padding: 6px 0 !important;
  overflow-x: overlay;
  > div {
    max-width: 80%;
    width: 100%;

    > div {
      max-width: none !important;

      > div {
        width: fit-content;
      }
    }
  }

  > ul {
    justify-content: flex-end;
    display: flex;
    > li {
      padding: 0 3px !important;
      > em {
        display: none;
      }
    }
  }
}

.collum {
  padding: 0 20% 12px 0 !important;
  margin: 0 0 12px 0 !important;

  border-bottom: 1px solid var(--color-background-04);

  > div {
    padding: 0 !important;
  }
}

.add-buttom {
  margin-top: 16px;
  width: fit-content !important;
}

.add-buttom-empty-card-state {
  margin: 0 auto;
}
