.status {
  height: 52px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 8px !important;
  border-radius: 8px;

  padding: 12px;

  border: none !important;

  position: relative;

  > div:first-child {
    height: 28px;
    width: 28px;

    border-radius: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 12px;
    border: none;
  }
}

.pending-status {
  background: var(--color-status-disabled-t45) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-default-base) !important;

  > div:first-child {
    color: var(--color-status-default-base) !important;
    background-color: var(--color-status-default-t15) !important;
    border: none;
  }
}
.budget-status {
  background: var(--color-status-warning-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-warning-base) !important;

  > div:first-child {
    background: var(--color-status-warning-t15) !important;

    > i {
      color: var(--color-status-warning-base) !important;
    }
  }
}
.approved-status {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-success-base) !important;

  > div:first-child {
    background: var(--color-status-success-t15) !important;
    > i {
      color: var(--color-status-success-base) !important;
    }
  }
}
.reproved-status {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-danger-base) !important;

  > div:first-child {
    background: var(--color-status-danger-t15) !important;
    > i {
      color: var(--color-status-danger-base) !important;
    }
  }
}
.partialDelivery-status {
  background: var(--color-status-warning-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-warning-base) !important;

  > div:first-child {
    background: var(--color-status-warning-t15) !important;
    > i {
      color: var(--color-status-warning-base) !important;
    }
  }
}
.delivery-status {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-success-base) !important;

  > div:first-child {
    background: var(--color-status-success-t15) !important;
    > i {
      color: var(--color-status-success-base) !important;
    }
  }
}

.canceled-status {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-default-base) !important;
  border: 1px solid var(--color-status-danger-base) !important;

  > div:first-child {
    background: var(--color-status-danger-t15) !important;
    > i {
      color: var(--color-status-danger-base) !important;
    }
  }
}

.cardTotal-separator-line {
  margin: 16px 0 16px 0;
  height: 1px;
  width: 100%;

  background-color: var(--color-background-04);
}
