.container {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;

  .first-button {
    margin-right: 6px;
  }
}
