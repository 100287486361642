.status {
  height: 24px !important;
  width: fit-content;

  display: flex;
  align-items: center;

  border-radius: 8px !important;

  padding: 0 10px !important;

  border: none !important;

  position: relative;

  .button-spinner {
    margin: 0 !important;
    > div {
      height: 10px !important;
      width: 10px !important;
      border: 2px solid var(--color-primary-base);
      border-color: var(--color-primary-base) transparent transparent
        transparent;
    }
  }
}

.status-with-rightIcon {
  padding: 0 5px 0 10px !important;
}

.circular {
  border-radius: 50% !important;
  height: 35px !important;
  width: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
