.card-dates {
  background-color: var(--color-background-01);
  border-radius: 8px;
  padding: 16px 16px 16px 16px;
  border: 1px solid var(--color-background-04) !important;
  width: 100% !important;
}

.margin-bottom {
  margin-bottom: 20px;
}
