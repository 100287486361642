.register-type > div > span:last-child > div > div {
  max-width: 165px !important;
}

.row-radio-group {
  margin-bottom: 25px;
  .radio-group {
    display: flex;
    justify-content: space-between;
  }
}
