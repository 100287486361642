.print-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1033;
  background: var(--color-white);
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.back-button-container {
  position: fixed;
  bottom: 10px;
  left: 0px;
  z-index: 1034;
  width: 100%;
  display: flex;
  justify-content: center;
}
