.modal-container {
  padding: 20px;
}

.modal-body {
  display: flex;
  align-items: baseline;
}

.modal-footer {
  display: flex;
}

.highlighted-text {
  color: var(--color-status-danger-base);
}