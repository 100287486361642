.row-products-table {
  height: 49px;

  > div {
    display: flex;
    align-items: center;
  }

  .first-collum > div:first-child {
    margin-right: 8px;
  }
}

.rows:not(:checked) {
  color: var(--color-text-300) !important;
  text-decoration: line-through !important;
}

.rows:has(:checked) {
  color: var(--color-text-50) !important;
  text-decoration: none !important;
}

.status-4 {
  color: var(--color-text-300) !important;
  text-decoration: line-through !important;
}

.status-2 {
  color: var(--color-text-300) !important;
  text-decoration: line-through !important;
}
