.print-modal-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
