.content {
  width: 100%;
  display: flex;

  .side-left {
    width: 36% !important;
    margin-right: 15px;
  }
  .side-right {
    width: 64% !important;
    .footer-buttom {
      display: flex;

      margin-top: 28px;
      padding-top: 16px;
      border-top: 1px solid var(--color-background-04);

      > button:first-child {
        margin-right: 10px;
      }
    }
  }
}
