.Form {
  width: 100%;
  max-width: 415px;

  button {
    margin-top: 38px;
  }

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  .forgot-login-link {
    margin: auto 0;
  }
}
.divRememberLogin {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
}

.icon-logo {
  margin-bottom: 40px;
}

.copyright {
  margin-top: 40px;

  text-align: center;
}
