.status {
  height: 52px;
  width: 100%;

  display: flex;
  justify-content: start;
  align-items: center;

  border-radius: 8px !important;

  cursor: default;

  padding: 12px;

  border: none !important;

  .div-icon {
    height: 28px;
    width: 28px;

    border-radius: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 12px;
    border: none;
  }
}
