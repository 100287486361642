.link-delivery-button {
  padding: 0 4px 0 4px !important;
  height: 16px !important;
  &:hover {
    color: var(--color-primary-base) !important;
    background-color: var(--color-primary-t15) !important;
    border-radius: 4px !important;
    padding: 0 4px 0 4px !important;
    height: 16px !important;
  }
}
