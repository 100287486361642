.margin-bottom {
  margin-bottom: 18px;
}
.Container:not(.margin-bottom) {
  margin-bottom: 0px;
}
.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 100px;
  width: 100%;

  .tag {
    > p > p {
      font-size: var(--font-size-xxs) !important;
      font-weight: var(--font-weight-bold) !important;
    }
  }

  .input-container {
    width: 100%;
    position: relative;
    border-radius: 8px;

    .empyt {
      height: 16px;
      position: absolute;
      right: 50%;
      top: 54px;

      width: 0 !important;

      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        z-index: 99999 !important;
        margin: 0 !important;
      }
    }
  }

  .Icon,
  .search {
    position: absolute;
    top: 10px;
  }

  .Icon {
    right: 12px;
  }

  .search {
    left: 12px;
  }

  .errorText {
    margin-top: 5px;
    margin-bottom: 0 !important;
    font-family: var(--font-family);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xs);
    line-height: var(--font-line-height-xxs);
    color: var(--color-status-danger-base);
  }

  .TextInput > div {
    padding: 0 12px 0 40px !important;
  }

  .TextInput-without-searchIcon > div {
    padding-left: 12px !important;
    > span {
      padding-left: 0 !important;
      > input {
        padding-left: 0 !important;
      }
    }
  }

  .error {
    .TextInput {
      border: 1px solid var(--color-status-danger-base) !important;
      &:hover {
        border: 1px solid var(--color-status-danger-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-danger-base) !important;
      }
    }
  }

  .success {
    .TextInput {
      border: 1px solid var(--color-status-success-base) !important;

      &:hover {
        border: 1px solid var(--color-status-success-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-success-base) !important;
      }
    }
  }

  .with-right-icon {
    .TextInput {
      padding-right: 32px;
    }
  }

  .div-label {
    display: flex;
    .label {
      margin-right: 3px;
    }
  }
}

.chevron-down {
  animation: rotate-down 0.4s normal forwards;
}

.chevron-up {
  animation: rotate-up 0.4s normal forwards;
}

@keyframes rotate-down {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-up {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.option {
  font-family: var(--font-family) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-light) !important;
  font-size: var(--font-size-xs) !important;
  background-color: var(--color-background-01);
}

.option-disabled {
  color: var(--color-text-400) !important;
  cursor: not-allowed !important;
}

.option-disabled:active {
  pointer-events: none;
}

.option-avatar {
  height: 43px !important;
  padding: 0 16px !important;
}

.empty-state {
  padding: 16px 8px;
  color: var(--color-text-50);
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-03);
  }
}

.static-option {
  padding: 16px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .text {
    margin-left: 16px;
    color: var(--color-text-200);
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  &:hover {
    background-color: var(--color-background-03);
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: var(--color-background-04);
}

.container-placeholder {
  height: 100%;
  align-items: center;
  > div:first-child {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--color-text-600);
  }
}
