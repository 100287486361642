.container {
  display: flex;
  height: calc(100% - 52px);
  justify-content: center;
  align-items: center;

  .div-content {
    max-width: 350px;
    text-align: center;

    > div,
    > i {
      margin: auto;
    }
    > i {
      margin-bottom: 24px;
    }
    > div:nth-child(3) {
      text-align: center;
      margin-top: 6px;
      margin-bottom: 24px;
    }

    > div > div {
      justify-content: center;
    }
  }
}
