.Tag {
  height: auto;
  min-height: 16px !important;
  width: fit-content;
  display: flex !important;
  align-items: center !important;

  border-radius: 3px !important;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}

.Tag-with-close-x {
  padding-left: 5px;
  padding-right: 2px !important;
}

.Tag-without-close-x {
  padding: 0 5px;
}
