.success-status {
  background: var(--color-status-success-t15);
  color: var(--color-status-success-base);
}
.status-hover.success-status:hover {
  background: var(--color-status-success-t30) !important;
}

.danger-status {
  background: var(--color-status-danger-t15);
  color: var(--color-status-danger-base);
}
.status-hover.danger-status:hover {
  background: var(--color-status-danger-t30) !important;
}

.attention-status {
  background: var(--color-status-warning-t15);
  color: var(--color-status-warning-base);
}
.status-hover.attention-status:hover {
  background: var(--color-status-warning-t30) !important;
}

.primary-status {
  background: var(--color-status-primary-t15);
  color: var(--color-status-primary-base);
}
.status-hover.primary-status:hover {
  background: var(--color-status-primary-t30) !important;
}

.default-status {
  background: var(--color-status-default-t15);
  color: var(--color-status-default-base);
}
.status-hover.default-status:hover {
  background: var(--color-status-default-t30) !important;
}

.disabled-status {
  background: var(--color-status-disabled-t15);
  color: var(--color-status-disabled-base);
}

.disabled-status-card {
  background: var(--color-status-disabled-t15) !important;
  color: var(--color-status-disabled-base) !important;
  cursor: not-allowed;
}

.status-hover.disabled-status:hover {
  background: var(--color-status-disabled-t30) !important;
}

.status-hover {
  cursor: pointer !important;
}
