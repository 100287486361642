.content {
  padding: 20px;
  .radio-button-row {
    margin-bottom: 25px;
    .radio-button {
      display: flex;
      justify-content: space-between;
    }
  }
}
