.container-capsule {
  > div > div {
    padding: 0 !important;
    &:first-child {
      margin-right: 6px;
    }
  }
  > div:first-child {
    margin-bottom: 12px;
  }
}

.content-capsule > div {
  height: 100%;

  display: flex;
  text-align: center;
  align-items: center;

  border-radius: 8px;
  background-color: var(--color-background-03);
}
