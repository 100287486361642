.container {
  > div:first-child {
    margin-bottom: 15px !important;
  }
}

.button-action {
  background: transparent !important;
  border: none !important;
  position: absolute !important;
  top: 5px;
  right: 5px;

  button {
    height: 32px !important;
    width: 32px !important;

    background: transparent !important;
    border: 1px solid var(--color-background-04) !important;

    i {
      color: var(--color-text-300) !important;
    }
  }
}

.rigth-button-two {
  right: 7px;
  height: 32px !important;

  background: transparent !important;
  border: 1px solid var(--color-background-04) !important;
  color: var(--color-text-300) !important;

  > div > i {
    color: var(--color-text-300) !important;
  }
}

.buttonLeft-without-buttonRight {
  right: 42px !important;
}

.button-edit {
  height: 32px !important;
  margin-right: 5px;
}

.button-with-permission {
  margin-right: 42px;
  background-color: var(--color-background-01) !important;
  color: var(--color-text-300) !important;

  > div:first-child > i {
    color: var(--color-text-300) !important;
  }

  border: 1px solid var(--color-background-04) !important;
}
