.content {
  width: 100%;
  display: flex;

  align-items: center;

  .left-icon {
    margin-right: 11px !important;
  }

  .div-description {
    width: 100%;

    .description {
      width: 100%;
    }

    .text {
      max-width: 100%;
      height: fit-content !important;
    }
  }
}
