.content-side-right-menu {
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% + 14px);
  padding-right: 10px;
}

.delivery-dropdown-row {
  margin-right: 6px;
}
