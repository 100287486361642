.title {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
  color: var(--color-text-100) !important;
  margin-bottom: 14px !important;
}

.cardTotal {
  background-color: var(--color-background-01) !important;
  border-radius: 8px;
  padding: 16px 16px 0px 27px;
  border: 1px solid var(--color-background-04) !important;
  width: 100% !important;
}

.cardTotal-separator-line {
  border: 1px solid var(--color-background-04) !important;
  width: 97%;
  margin-left: 1.5%;
  height: 1px;
  margin-top: 34px;
}
