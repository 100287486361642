.container-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  border-radius: 50px;

  margin-right: 12px;
}
