.divSecurity {
	width: 350px;

	.input {
		margin-bottom: 15px !important;
	}

	.last-input {
		margin-bottom: 25px !important;
	}
}
