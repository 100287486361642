.status {
  background: var(--color-status-disabled-t15);
  border: 1px solid var(--color-status-disabled-base) !important;
  color: var(--color-text-50) !important;

  > div:first-child {
    background: var(--color-status-disabled-base);
    > i {
      color: var(--color-text-200) !important;
    }
  }

}
