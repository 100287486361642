.Label {
  display: flex;
  align-items: center;

  height: 15px;
  p {
    margin-bottom: 0px !important;
  }
}

.label-required::after {
  color: #e32;
  content: ' *';
  margin-left: 3px;
}

.div-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  .label {
    color: var(--color-text-50) !important;
  }
}

.icon-status {
  height: 15px !important;
  margin-left: 6px;
  &::before {
    font-family: 'icomoon' !important;
    content: '\e939';
    color: var(--color-status-warning-base);
  }
}

.icon-check {
  height: 15px !important;
  margin-left: 6px;
  border-radius: 50%;
  &::before {
    font-family: 'icomoon', sans-serif !important;
    content: '\e939';
    color: white;
  }
}

.icon-loading {
  margin-left: 6px !important;

  height: 15px;
  width: 15px;
}

.bottom-margin {
  margin-bottom: 6px;
}
