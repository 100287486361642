.description-warning {
  height: 26px;

  display: flex;
  align-items: center;

  > i {
    margin-right: 6px;
  }
}

.top-divider {
  height: 1px;
  width: 100%;

  background: var(--color-background-04);
}
