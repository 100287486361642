.button {
  border: none;
  background: none;

  padding: 0 !important;

  > i {
    cursor: grab !important;
  }
}
