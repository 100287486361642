.container {
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-style: normal;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  background-color: var(--color-background-04);
  color: var(--color-text-200);
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 2px;
}

.container-multiple-keys {
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-style: normal;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  background-color: var(--color-background-04);
  color: var(--color-text-200);
  border-radius: 4px;
}
