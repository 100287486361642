.body {
  border-radius: 8px;
  padding: 12px;
  border: 1px solid var(--color-background-04);
  background-color: var(--color-background-02);
  margin-bottom: 6px;

  &:hover {
    background-color: var(--color-background-04);
  }

  &:focus-within {
    border-color: var(--color-primary-base);
  }
}

.content {
  display: flex;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0;
  border: none;
  background: transparent;

  cursor: pointer;

  > div {
    user-select: none;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 8px;

  .icon-button {
    cursor: pointer;

    &:hover {
      color: var(--color-text-50) !important;
      background-color: var(--color-text-600) !important;
    }
  }
}
