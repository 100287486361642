.loader {
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  > div:not(.background-loading) {
    box-sizing: border-box;

    border: 3px solid;
    border-color: var(--color-primary-base) transparent transparent transparent;

    display: block;
    position: absolute;

    width: 16px;
    height: 16px;

    border-radius: 50%;

    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  .background-loading {
    box-sizing: border-box;

    border: 3px solid;
    border-color: var(--color-primary-t15) var(--color-primary-t15)
      var(--color-primary-t15) var(--color-primary-t15);

    display: block;
    position: absolute;

    width: 16px;
    height: 16px;

    border-radius: 50%;
  }
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
