.conference-card {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.card-container {
  width: 80%;
}

.status-container {
  width: 20%;
}

.status-card {
  color: var(--color-text-50) !important;
}

.container-buttons {
  justify-content: space-between;
  margin: 15px 0 15px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  gap: 5px;
}

.conference-status {
  background: var(--color-status-primary-t30);
  border: 1px solid var(--color-status-primary-base) !important;

  > div:first-child {
    background: var(--color-status-primary-t30);
    > i {
      color: var(--color-status-primary-base) !important;
    }
  }
}

.finished-status {
  background: var(--color-status-success-t15);
  border: 1px solid var(--color-status-success-base) !important;

  > div:first-child {
    background: var(--color-status-success-t15);
    > i {
      color: var(--color-status-success-base) !important;
    }
  }
}
