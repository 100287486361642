.ant-select-single {
  .ant-select-selection-search-input {
    height: 100% !important;
    padding-left: 26px !important;
  }
}

.ant-select-selection-item {
  padding-right: 16px !important;
  color: var(--color-text-50);
}

.ant-input-group-addon {
  padding: 0px !important;

  .ant-select {
    margin: 0px !important;
    height: 34px !important;
    border: none !important;
    background: var(--color-background-03) !important;
    border-radius: 8px 0px 0px 8px !important;
  }
}

.ant-select-multiple .ant-select-selection-placeholder {
  padding-left: 26px !important;
  left: 14px !important;
}

.ant-select-multiple ~ div {
  .ant-select-item-option-selected {
    background-color: var(--color-background-01) !important;
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select {
  height: 36px !important;
  width: 100%;
  background: var(--color-background-01);
  border: 1px solid var(--color-background-06);
  border-radius: 8px;

  &:disabled {
    background-color: var(--color-status-disabled-t15) !important;
  }

  &::placeholder {
    color: var(--color-text-400) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;
    line-height: var(--font-line-height-xxs);
  }

  .ant-select-selector {
    height: 100% !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-family: var(--font-family) !important;
    color: var(--color-text-50);
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;

    .ant-select-selection-item {
      .icon-favorite {
        display: none !important;
      }

      > div {
        > div:first-child {
          font-size: var(--font-size-xs);

          > span {
            height: 24px !important;
            width: 24px !important;

            > span {
              > p {
                font-size: var(--font-size-xxs) !important;
                line-height: var(--font-line-height-xxxs) !important;
              }
            }
          }
        }

        > div:last-child {
          > p:first-child {
            font-size: var(--font-size-xs) !important;
            line-height: var(--font-line-height-xxxs);
          }
        }
      }
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 36px !important;
    }

    .ant-select-selection-item {
      padding: 0 !important;
    }
  }
}

.ant-select-dropdown {
  container-name: dropdown;
  container-type: inline-size;
  padding: 8px 0;

  .ant-select-item-option {
    > div {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .ant-select-item-option-active {
    background-color: var(--color-background-03) !important;
  }

  .ant-select-item-option-selected {
    background-color: var(--color-primary-t15) !important;
    border-radius: 0px !important;

    .ant-select-item-option-state {
      display: none;
    }
  }
}

@container dropdown (width < 100px) {
  .ant-select-item-option {
    padding: 10px 0px !important;
  }
}

@container dropdown (width > 100px) and (width < 200px) {
  .ant-select-item-option {
    padding: 10px 8px !important;
  }
}

@container dropdown (width > 200px) and (width > 1080px) {
  .ant-select-item-option {
    padding: 10px 16px !important;
  }
}

.ant-select-selection-search {
  margin-inline-start: 0 !important;
}

.ant-select-item-empty {
  .ant-empty {
    display: none;
  }
}

.ant-select-focused {
  ~ i {
    color: var(--color-primary-base) !important;
  }

  border: 1px solid var(--color-primary-base) !important;
}

.ant-select {
  display: flex;
  align-items: center;
  height: 36px !important;
  background-color: var(--color-background-01);

  box-shadow: none !important;
  width: 100%;

  border-radius: 8px !important;

  font-family: var(--font-family) !important;
  font-size: var(--font-size-xs) !important;
  line-height: var(--font-line-height-xxs) !important;
  font-weight: normal;
  color: var(--color-text-50) !important;
  border: 1px solid var(--color-background-06);

  > div {
    border: none !important;
    background-color: transparent !important;
    width: 100% !important;
    box-shadow: none !important;
  }
}

.ant-select-disabled {
  background-color: var(--color-status-disabled-t15) !important;
  border: 1px solid var(--color-status-disabled-t45) !important;
  cursor: not-allowed !important;
}

//description value with text-50 color instead disabled color selected
.ant-select-selection-overflow-item
  > span
  > div
  > div
  > span
  > div
  > div
  > div
  > div {
  color: var(--color-text-50) !important;
}
