.content-permission-wrapper {
  cursor: not-allowed;
  min-width: fit-content !important;
  > button {
    cursor: not-allowed;
    user-select: none;
    // This was the cause of the bug #6061, just commenting in case this breaks in other places
    // pointer-events: none;

    color: var(--color-text-300) !important;

    > div:first-child > i {
      cursor: not-allowed;
      color: var(--color-text-300) !important;
    }
  }
}
