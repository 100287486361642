.container {
  padding-bottom: 8px;

  .radio-button {
    > label {
      padding: 0px 16px;
      height: 36px !important;
    }
    &:hover {
      background: var(--color-background-03);
    }
  }

  .content,
  .trash {
    height: 36px;
    align-items: center;

    padding: 0 16px;
    &:hover {
      background: var(--color-background-03);
      cursor: pointer;
    }

    > div:last-child {
      margin-left: 4px;
    }
    > i {
      margin-right: 8px;
    }
  }

  .content:hover {
    background: var(--color-background-03);
  }
  .trash:hover {
    background: var(--color-status-danger-t15);
    > i {
      color: var(--color-status-danger-base) !important;
    }
  }
}

.divider {
  margin: 8px 0;
  background: var(--color-background-04);

  height: 1px;
  width: 100%;
}
