.Button {
  display: flex !important;
  position: relative !important;
  align-items: center !important;
  justify-content: center !important;

  border: 2px solid transparent !important;

  padding-right: 12px !important;
  padding-left: 12px !important;

  border-radius: 8px !important;
  height: 36px !important;
}

.fullWidth {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  border-radius: 8px !important;
  height: 36px !important;
}

.variable {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  padding: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  height: 36px !important;
  width: 100% !important;

  border-radius: 8px !important;
}

.div-rightIcon {
  margin-left: 10px !important;
  margin-right: 4px !important;
  border: none !important;
}

.primary,
.secondary,
.tertiary,
.link {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal !important;

  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-xs) !important;
  line-height: 160% !important;
}
.primary,
.secondary,
.link {
  border: 2px solid transparent !important;
}

.circle {
  width: 36px !important;
  height: 36px !important;
  border-radius: 8px !important;
  padding: 0 !important;
  .rightIcon,
  .leftIcon {
    margin: auto !important;
  }
  .div-leftIcon,
  .div-rightIcon {
    margin: auto !important;
  }
}

.children {
  display: flex !important;
}
.center {
  justify-content: center !important;
}
.start {
  justify-content: start !important;
}
.flex-end {
  justify-content: flex-end !important;
}

.full-width {
  width: 100% !important;
}

.no-color {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal !important;

  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-xs) !important;
  line-height: 160% !important;

  border: none !important;
  border-radius: 8px !important;
}

.size-xs {
  height: 28px !important;
}
.size-xs.circle {
  width: 28px !important;
}

.size-xl {
  height: 36px !important;
}

.button-with-dropdown {
  border-radius: 8px 0 0 8px !important;
}

.divider {
  position: absolute;
  left: 0;
  height: 25px;
  width: 1px;

  z-index: 1;
  margin-top: 5.5px;
  background: var(--color-primary-dark);
}

.button-on-dropdown {
  border-radius: 0 8px 8px 0 !important;
}
