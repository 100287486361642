.ant-input-number-group-wrapper.input-number-with-left-icon.input-number-with-dropdown-left {
  padding-right: 50px !important;
}
.ant-input-number-group-wrapper.input-number-with-right-icon.input-number-with-dropdown-right {
  padding-left: 50px !important;
}

.ant-input-number-group-wrapper {
  height: 100%;
  width: 100%;

  .ant-input-number-group {
    height: 100%;
    .ant-input-number-group-addon {
      width: 48px !important;
      background-color: transparent !important;
      border: none !important;

      .ant-select-selection-item {
        color: var(--color-text-300) !important;
        padding-right: 0px !important;
      }

      .ant-select-open {
        border: none !important;
      }
    }
  }
}

.ant-input-number {
  height: 100%;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;

  &:not(.input-number-with-dropdown-right):not(.input-number-with-right-icon) {
    border-radius: 0 8px 8px 0 !important;
    .ant-input-number-input-wrap {
      border-radius: 0 6px 6px 0 !important;
    }
  }
  &:not(.input-number-with-dropdown-left):not(.input-number-with-left-icon) {
    border-radius: 8px 0 0 8px !important;
    .ant-input-number-input-wrap {
      border-radius: 8px 0 0 8px !important;
    }
  }
  &:not(.input-number-with-dropdown-left):not(.input-number-with-left-icon):not(
      .input-number-with-dropdown-right
    ):not(.input-number-with-right-icon) {
    border-radius: 8px !important;
    .ant-input-number-input-wrap {
      border-radius: 8px !important;
    }
  }

  .ant-input-number-input-wrap {
    height: 100%;
    background-color: var(--color-background-01);
    .ant-input-number-input {
      height: 100%;
      color: var(--color-text-50);
      font-size: var(--font-size-xs);

      &::placeholder {
        color: var(--color-text-400);
      }

      &:disabled {
        background-color: var(--color-status-disabled-t15);
        border-right: 1px solid var(--color-background-04);
        border-radius: 0px;
      }
    }
  }
}

.ant-input-number-handler-wrap {
  border-radius: 8px;
  .ant-input-number-handler-down,
  .ant-input-number-handler-up {
    border: none;
    border-left: 1px solid var(--color-background-04) !important;
    &:hover {
      > span {
        color: var(--color-text-300) !important;
      }
    }
    > span {
      color: var(--color-text-400) !important;
    }
  }
  .ant-input-number-handler-down {
    border-top: 1px solid var(--color-background-04) !important;
  }
}

.input-number-with-right-icon.input-number-with-dropdown-right,
.input-number-with-left-icon.input-number-with-dropdown-left {
  .ant-input-number-group {
    position: inherit;
    > div:last-child,
    > div:first-child {
      position: absolute;

      height: 100%;
      padding: 0 !important;
      > div {
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.input-number-with-left-icon.input-number-with-dropdown-left {
  .ant-input-number-group {
    > div:first-child {
      left: 0;
    }
  }
}
.input-number-with-right-icon.input-number-with-dropdown-right {
  .ant-input-number-group {
    > div:last-child {
      right: 0;
    }
  }
}

.without-select-arrows {
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}
