.footer-button {
  display: flex;
  gap: 10px;
}

.avatar-list {
  display: flex;
  > div:first-child {
    margin-right: 10px;
  }
}

.marginBottom {
  margin-bottom: 5px;
}

.checkBoxStyles {
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-xs);
  margin-bottom: 5px;
}
