.checkbox {
  > div {
    background-color: var(--color-background-03);
    padding: 14px 20px;
    border-radius: 4px;
  }
}
.footer-button {
  display: flex;
  gap: 10px;
}
