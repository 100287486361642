.divider {
  border-left: 1px;
  border-left-style: solid;
  height: 57px;
  margin-left: 10px;
  margin-right: 10px;
  border-left-color: var(--color-background-04);
}

.horizontal-divider {
  border-top: 1px solid var(--color-background-04);
}