.status-card {
  color: var(--color-text-50) !important;
}

.waitingApproval-status {
  background: var(--color-status-default-t15);
  border: 1px solid var(--color-status-default-base) !important;

  > div:first-child {
    background: var(--color-status-default-t15);
    > i {
      color: var(--color-status-default-base) !important ;
    }
  }
}

.disapproved-status,
.canceled-status {
  background: var(--color-status-danger-t15);
  border: 1px solid var(--color-status-danger-base) !important;

  > div:first-child {
    background: var(--color-status-danger-t15);
    > i {
      color: var(--color-status-danger-base) !important;
    }
  }
}

.relesed-status,
.confirmedProvider-status,
.totalReceived-status {
  background: var(--color-status-success-t15);
  border: 1px solid var(--color-status-success-base) !important;

  > div:first-child {
    background: var(--color-status-success-t15);
    > i {
      color: var(--color-status-success-base) !important;
    }
  }
}

.partialReceived-status {
  background: var(--color-status-warning-t15);
  border: 1px solid var(--color-status-warning-base) !important;

  > div:first-child {
    background: var(--color-status-warning-t15);
    > i {
      color: var(--color-status-warning-base) !important;
    }
  }
}
