.content-avatar {
  width: 410px;
  padding: 20px;

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;

    margin-bottom: 15px;
  }

  ul {
    margin-top: 20px;
    margin-bottom: 20px;

    list-style: none;

    padding: 0;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '•';
        color: var(--color-text-50);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
      }
    }
  }
}
