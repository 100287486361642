.footer-button {
  display: flex;
  justify-content: space-between;
  > button:first-child {
    margin-right: 10px;
  }
  > div {
    display: flex;
  }
}
