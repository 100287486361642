.div-avatar {
  width: calc(100% - 40px);
  margin-left: 12px;

  .description {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
