.manyAvatarContainer {
  & > div {
    margin-right: -6px;
  }
}

.singleAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-right: -4px;
    width: 48px;
  }
}
