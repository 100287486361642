.status {
  height: 24px;
  width: fit-content;

  display: flex;
  align-items: center;

  border-radius: 8px;

  padding: 10px;

  position: relative;

  .button-spinner {
    margin: 0 !important;
    > div {
      height: 10px !important;
      width: 10px !important;
      border: 2px solid var(--color-primary-base);
      border-color: var(--color-primary-base) transparent transparent
        transparent;
    }
  }
}

.status-without-description {
  height: 28px !important;
  width: 28px;
  align-items: center;
  border-radius: 20px !important;
  padding: 0 !important;
  border: none !important;
  position: relative;

  margin: auto;

  > i {
    margin: auto;
  }
}

.status-with-big-Size {
  height: 78px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  padding: 10px 0 10px 30px;
  position: relative;
}

.status-with-mid-Size {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  padding: 10px 0 10px 30px;
  border: 1px solid var(--color-status-disabled-base) !important;
  position: relative;
}

.status-with-rightIcon {
  padding: 0 5px 0 10px !important;
}

.default-status {
  background: var(--color-status-disabled-t15) !important;
  color: var(--color-text-50) !important;
  border: none;
}
.waitingApproval-status {
  background: var(--color-status-default-t15) !important;
  color: var(--color-status-default-base) !important;
  border: none;
}
.disapproved-status {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-danger-base) !important;

  border: none;
}
.canceled-status {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-danger-base) !important;

  border: none;
}
.relesed-status {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-success-base) !important;
  border: none;
}
.confirmedProvider-status {
  background: var(--color-status-success-t15) !important;

  color: var(--color-status-success-base) !important;
  border: none;
}
.partialReceived-status {
  background: var(--color-status-warning-t15) !important;

  color: var(--color-status-warning-base) !important;
  border: none;
}
.totalReceived-status {
  background: var(--color-status-success-t15) !important;

  color: var(--color-status-success-base) !important;
  border: none;
}
.draft-status {
  background: var(--color-status-disabled-t15) !important;

  color: var(--color-status-disabled-base) !important;
  border: none;
}

.waitingApproval-icon-color {
  color: var(--color-status-default-base) !important;
  border: none;
}
.disapproved-icon-color {
  color: var(--color-status-danger-base) !important;
  border: none;
}
.canceled-icon-color {
  color: var(--color-status-danger-base) !important;
  border: none;
}
.relesed-icon-color {
  color: var(--color-status-success-base) !important;
  border: none;
}
.confirmedProvider-icon-color {
  color: var(--color-status-success-base) !important;
  border: none;
}
.partialReceived-icon-color {
  color: var(--color-status-warning-base) !important;
  border: none;
}
.totalReceived-icon-color {
  color: var(--color-status-success-base) !important;
  border: none;
}
.draft-icon-color {
  color: var(--color-status-disabled-base) !important;
  border: none;
}

.icon {
  margin-right: 7px !important;
}

.cardTotal-separator-line {
  border: 1px solid var(--color-background-04) !important;
  width: 100%;
  height: 1px;
}
