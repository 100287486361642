.container {
  height: calc(100% - 157px);
  border-top: 1px solid var(--color-background-04);

  .row {
    overflow-y: auto;

    margin: 0 !important;
    padding: 16px 14px;

    .separate-x {
      position: relative;
      > i {
        position: absolute;
        bottom: 12px;
      }
    }
    .repeat-col {
      padding-left: 18px !important;
    }

    .horizontal-divider {
      width: 100%;
      height: 1px;
      background-color: var(--color-background-04);

      margin: 0 6px;
    }
  }

  .details-menu {
    background: var(--color-background-02);
    height: 100%;
    width: 250px;

    padding: 16px;

    position: relative;

    > div:first-child {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--color-background-04);
    }

    .details {
      margin-top: 12px;

      > div > div {
        margin-bottom: 2px;
      }

      > div:first-child {
        width: 147px;
      }
      > div:last-child {
        width: 65px;
      }
    }

    .button-calculation {
      width: calc(100% - 32px);

      position: absolute;
      bottom: 16px;
    }
  }
}

.bottom {
  height: 81px;
  padding: 12px 20px;
  border-top: 1px solid var(--color-background-04);
}
