.mainContainer {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid var(--color-background-04);
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.purchaseOrderListDefault {
  display: flex;
  gap: 4px;
  width: 87%;
}

.purchaseOrderList {
  display: flex;
  gap: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 87%;
}

.purchaseOrderList::-webkit-scrollbar {
  margin-top: 2px;
  height: 4px;
}

.purchaseOrderContainer {
  border-radius: 4px;
  background-color: var(--color-background-04);
  padding: 0px 5px;
  text-align: center;
  min-width: 50px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.purchaseNumber {
  display: flex;
  align-items: center;
  padding: 3px;
  height: 20px;
  min-width: 70%;
  text-align: right;
  margin-top: 0px;
  cursor: default;
}

.removePurchase {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
}

.addPurchaseOrderButton {
  border-radius: 4px;
  cursor: pointer;
  font-size: 20;
  font-weight: bold;
  background-color: var(--color-background-04);
  padding: 2px 8px;
}

.purchaseOrderListTooltip {
  width: 280px;
  position: relative;
  padding: 10px 50px 20px 50px;
}

.totalPurchaseOrder {
  list-style: none;
  position: absolute;
  left: 3px;
  bottom: -10px;
  font-size: 13px;
}

.purchaseOrderItem {
  font-size: 12px;
}

.placeHolder {
  padding: 0;
  margin: 1px 0 0 0;
  color: var(--color-background-24);
  font-size: 13.5px;
}

.disabledStyle {
  background-color: var(--color-background-02);
  cursor: not-allowed;
}
