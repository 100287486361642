.ant-collapse-item-active {
  & > .ant-collapse-header {
    margin-left: 0 !important;
    border-radius: 0 !important;
    &:hover {
      //background-color: var(--color-background-02);
    }

    & > span > div > .chevron {
      transform: rotate(90deg);
    }
  }
}

.focus {
  background-color: var(--color-background-02);
}
