@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xbl9lp');
  src:  url('fonts/icomoon.eot?xbl9lp#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xbl9lp') format('truetype'),
    url('fonts/icomoon.woff?xbl9lp') format('woff'),
    url('fonts/icomoon.svg?xbl9lp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Vector:before {
  content: "\e989";
}
.icon-Cubes:before {
  content: "\e988";
  color: #a0a0a0;
}
.icon-ReturnIcon:before {
  content: "\e95b";
  color: #fff;
}
.icon-start:before {
  content: "\e95a";
}
.icon-Inventario:before {
  content: "\e959";
}
.icon-printer:before {
  content: "\e958";
}
.icon-Upsell:before {
  content: "\e957";
}
.icon-skype:before {
  content: "\e955";
}
.icon-long-arrow-right:before {
  content: "\e94e";
  color: #c4c4c4;
}
.icon-house:before {
  content: "\e94d";
}
.icon-mobile-chat:before {
  content: "\e920";
}
.icon-shopping-bag-chat:before {
  content: "\e923";
}
.icon-shopping-bag-ecommerce:before {
  content: "\e92a";
}
.icon-facebook:before {
  content: "\e987";
}
.icon-medicine:before {
  content: "\e982";
}
.icon-mask:before {
  content: "\e983";
}
.icon-capsules:before {
  content: "\e984";
}
.icon-medical-clipboard:before {
  content: "\e985";
}
.icon-medicine-bottle:before {
  content: "\e986";
}
.icon-Equal:before {
  content: "\e981";
}
.icon-synonym-square:before {
  content: "\e980";
}
.icon-Rename:before {
  content: "\e97e";
}
.icon-Drag:before {
  content: "\e97f";
}
.icon-gral:before {
  content: "\e97d";
}
.icon-approve-file:before {
  content: "\e979";
}
.icon-growth-graph:before {
  content: "\e97a";
}
.icon-weight-scale:before {
  content: "\e97b";
}
.icon-Approve:before {
  content: "\e97c";
}
.icon-landscape-text-file:before {
  content: "\e978";
}
.icon-Broken-Chain-3:before {
  content: "\e977";
}
.icon-calculator:before {
  content: "\e976";
}
.icon-box-add-alternative:before {
  content: "\e973";
}
.icon-gral-add:before {
  content: "\e974";
}
.icon-backup:before {
  content: "\e975";
}
.icon-chevron-up:before {
  content: "\e971";
}
.icon-box-wrong:before {
  content: "\e972";
}
.icon-cart:before {
  content: "\e970";
}
.icon-trash-line:before {
  content: "\e96f";
}
.icon-exclamation-warning:before {
  content: "\e96e";
}
.icon-counter:before {
  content: "\e96d";
}
.icon-invoice:before {
  content: "\e96b";
}
.icon-prescriber:before {
  content: "\e96a";
}
.icon-conical-flask-gear:before {
  content: "\e96c";
}
.icon-chat-bubble:before {
  content: "\e969";
}
.icon-diskette:before {
  content: "\e966";
}
.icon-BoxCorrect:before {
  content: "\e967";
}
.icon-reply:before {
  content: "\e968";
}
.icon-Unfavorite:before {
  content: "\e956";
}
.icon-disapproved:before {
  content: "\e952";
}
.icon-partialcube:before {
  content: "\e953";
}
.icon-time:before {
  content: "\e954";
}
.icon-instagram:before {
  content: "\e94c";
}
.icon-whatsapp:before {
  content: "\e94f";
}
.icon-mobile:before {
  content: "\e950";
}
.icon-telegran:before {
  content: "\e951";
}
.icon-pencil:before {
  content: "\e94a";
}
.icon-favorite:before {
  content: "\e94b";
}
.icon-Arrow-right:before {
  content: "\e900";
}
.icon-gear:before {
  content: "\e901";
}
.icon-exit:before {
  content: "\e902";
}
.icon-question-mark:before {
  content: "\e903";
}
.icon-delete-folder:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-email:before {
  content: "\e906";
}
.icon-exclamation-mark:before {
  content: "\e907";
}
.icon-exclamation-upside-down:before {
  content: "\e908";
}
.icon-like:before {
  content: "\e909";
}
.icon-lock:before {
  content: "\e90a";
}
.icon-simple-logo:before {
  content: "\e90b";
}
.icon-logo-bootys:before {
  content: "\e90c";
  color: #5e50f2;
}
.icon-menu-elipse:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e90f";
}
.icon-users-outline:before {
  content: "\e910";
}
.icon-Building:before {
  content: "\e911";
}
.icon-invoice-list:before {
  content: "\e912";
}
.icon-menu-ellipsis-horizontal:before {
  content: "\e913";
}
.icon-approve-tick:before {
  content: "\e914";
}
.icon-archive:before {
  content: "\e915";
}
.icon-attach-outline:before {
  content: "\e916";
}
.icon-bell:before {
  content: "\e917";
}
.icon-phone:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e919";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-chevron-right:before {
  content: "\e91b";
}
.icon-double-chevron-right:before {
  content: "\e91c";
}
.icon-close-x:before {
  content: "\e91d";
}
.icon-keyhole:before {
  content: "\e91e";
}
.icon-simple-cube:before {
  content: "\e91f";
}
.icon-external-link:before {
  content: "\e921";
}
.icon-user-mark:before {
  content: "\e922";
}
.icon-calculation:before {
  content: "\e924";
}
.icon-double-chevron-left:before {
  content: "\e925";
}
.icon-eye:before {
  content: "\e926";
}
.icon-close-eye:before {
  content: "\e927";
}
.icon-multimidia-equalizer:before {
  content: "\e928";
}
.icon-logo-bootys-login:before {
  content: "\e929";
  color: #5e50f2;
}
.icon-arrow-down:before {
  content: "\e92b";
}
.icon-arrow-up:before {
  content: "\e92c";
}
.icon-search-action:before {
  content: "\e92d";
}
.icon-trash:before {
  content: "\e92e";
}
.icon-edit:before {
  content: "\e92f";
}
.icon-shopping-bag:before {
  content: "\e930";
}
.icon-setting:before {
  content: "\e931";
}
.icon-conical-flask:before {
  content: "\e932";
}
.icon-external-link1:before {
  content: "\e933";
}
.icon-remove-account:before {
  content: "\e934";
}
.icon-Box:before {
  content: "\e935";
}
.icon-calendar:before {
  content: "\e936";
}
.icon-invoice1:before {
  content: "\e937";
}
.icon-copy:before {
  content: "\e938";
}
.icon-status-attention:before {
  content: "\e939";
}
.icon-bin-full:before {
  content: "\e93a";
}
.icon-buildings:before {
  content: "\e93b";
}
.icon-account:before {
  content: "\e93c";
}
.icon-document-info:before {
  content: "\e93d";
}
.icon-cube:before {
  content: "\e93e";
}
.icon-cubes:before {
  content: "\e93f";
}
.icon-connection:before {
  content: "\e940";
}
.icon-cash:before {
  content: "\e941";
}
.icon-document:before {
  content: "\e942";
}
.icon-clipboard:before {
  content: "\e943";
}
.icon-tag:before {
  content: "\e944";
}
.icon-tags:before {
  content: "\e945";
}
.icon-water-glass:before {
  content: "\e946";
}
.icon-library:before {
  content: "\e947";
}
.icon-basket:before {
  content: "\e948";
}
.icon-shipping-truck:before {
  content: "\e949";
}
.icon-packaging-classification:before {
  content: "\e95c";
}
.icon-capsules-colors .path1:before {
  content: "\e95d";
  color: rgb(46, 46, 46);
}
.icon-capsules-colors .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(46, 46, 46);
}
.icon-capsules-colors .path3:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(46, 46, 46);
}
.icon-capsules-colors .path4:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(46, 46, 46);
}
.icon-capsules-colors .path5:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-dosage:before {
  content: "\e95e";
}
.icon-pharmaceutical-form:before {
  content: "\e95f";
}
.icon-add-book:before {
  content: "\e960";
}
.icon-edit-file:before {
  content: "\e961";
}
