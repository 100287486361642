::-webkit-scrollbar {
  width: 13px !important;

  margin-top: 8px !important;
  margin-bottom: 8px !important;

  background: transparent !important;

  height: 13px !important;

  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent !important;
  background-clip: padding-box !important;
  border-radius: 8px !important;
  background-color: var(--color-text-400) !important;

  cursor: pointer !important;
}

.rc-virtual-list-scrollbar {
  width: 13px !important;

  background: transparent !important;

  .rc-virtual-list-scrollbar-thumb {
    border: 4px solid transparent !important;
    background-clip: padding-box !important;
    border-radius: 8px !important;
    background-color: var(--color-text-400) !important;
  }
}
