.divDropdown {
  border-radius: 8 !important;
  padding: 8px 0px !important;
  border: 1px solid var(--color-background-04) !important;

  .title {
    display: flex;
    height: 28px;

    align-items: center;
    padding: 0 12px;

    > p {
      user-select: none;
    }
  }

  .dropdownLabel {
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 0 !important;
    min-width: 150px;

    .icon {
      margin-right: 12px;
    }
    > span {
      display: flex;
    }
  }
}
