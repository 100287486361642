.collapse {
  background: var(--color-background-01) !important;
  border: 1px solid var(--color-background-04) !important;
  border-radius: 8px !important;
  margin-bottom: 25px !important;

  position: relative;

  .panel {
    border: none !important;
    padding: 0 25px 0 25px;

    > div:last-child {
      background: var(--color-background-01) !important;
      border-top: 1px solid var(--color-background-04) !important;

      > div {
        padding: 0;
      }
    }

    .panel-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      padding-top: 25px;
    }

    .body {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

.rotate-true {
  animation: rotate-true 0.4s normal forwards;
}
.rotate-false {
  animation: rotate-false 0.4s normal forwards;
}

@keyframes rotate-true {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate-false {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.div-color {
  position: absolute;

  width: 5px;
  height: 100%;

  left: 0;
  top: 0;
}

.brand {
  background: var(--color-primary-base) !important;
  border-radius: 8px 0 0 8px;
}
.default {
  background: var(--color-background-04) !important;
}
.success {
  background: var(--color-status-success-base) !important;
}
.error {
  background: var(--color-status-danger-base) !important;
}

.icon-with-tooltip {
  margin: 0 5px;
}
