.modal-content > div {
  height: 90vh;
}

.container {
  .content-step {
    height: 76px;
    border-bottom: 1px solid var(--color-background-04);

    .steps {
      margin: 12px 20px;
    }
  }
  height: 100%;
}
