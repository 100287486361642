.title {
  font-size: 15px;
  font-weight: 700;
}

.product {
  display: flex;
  align-items: center;
  margin-top: -15px;
}

.productText {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 10px;
}

.productColumnsInfo {
  font-size: 14;
  font-weight: 500;
  margin-top: -2px;
}
