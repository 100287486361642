.button_primary.ant-btn:not(.primary-button-mouseDown).button_status_default {
  background: var(--color-primary-base) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;

  &:hover {
    background: var(--color-primary-dark) !important;
  }

  &:focus {
    background: var(--color-primary-base) !important;
    border-color: var(--color-primary-darkest) !important;
  }

  &:disabled {
    color: var(--color-text-800) !important;
    background: var(--color-primary-t30) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-text-800) !important;
    }
    .button-badge {
      opacity: 0.3 !important;
    }
  }
}

.button_primary.ant-btn:not(.primary-button-mouseDown).button_status_danger {
  background: var(--color-status-danger-base) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;

  .button-badge {
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }

  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }

  &:hover {
    background: var(--color-status-danger--20) !important;
  }

  &:focus {
    background: var(--color-status-danger-base) !important;
    border-color: var(--color-status-danger--40) !important;
  }

  &:disabled {
    color: var(--color-text-800) !important;
    background: var(--color-status-danger-t30) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-text-800) !important;
    }
    .button-badge {
      opacity: 0.3 !important;
    }
  }
}
.button_primary.ant-btn:not(.primary-button-mouseDown).button_status_success {
  background: var(--color-status-success-base) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;

  .button-badge {
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }

  button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }

  &:hover {
    background: var(--color-status-success--20) !important;
  }

  &:focus {
    background: var(--color-status-success-base) !important;
    border-color: var(--color-status-success--40) !important;
  }

  &:disabled {
    color: var(--color-text-800) !important;
    background: var(--color-status-success-t30) !important;
    border-color: transparent !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-success-t30) !important;
    }

    .button-badge {
      border: 1px solid transparent !important;
      background: var(--color-background-02) !important;
      > div {
        color: var(--color-status-success-t30) !important;
      }
    }
  }
}
.button_primary.ant-btn:not(.primary-button-mouseDown).button_status_warning {
  background: var(--color-status-warning-base) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;

  .button-badge {
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }

  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }

  &:hover {
    background: var(--color-status-warning--20) !important;
  }

  &:focus {
    background: var(--color-status-warning-base) !important;
    border-color: var(--color-status-warning--40) !important;
  }

  &:disabled {
    color: var(--color-text-800) !important;
    background: var(--color-status-warning-t30) !important;
    border-color: transparent !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-warning-t30) !important;
    }

    .button-badge {
      border: 1px solid transparent !important;
      background: var(--color-background-02) !important;
      > div {
        color: var(--color-status-warning-t30) !important;
      }
    }
  }
}

.button_primary.ant-btn {
  .button-spinner > div {
    border: 3px solid var(--color-white) !important;
    border-color: var(--color-white) transparent transparent transparent !important;
  }
}

.button_secondary.ant-btn:not(
    .secondary-button-mouseDown
  ).button_status_default {
  background: var(--color-text-600);
  border: 1px solid transparent;
  color: var(--color-text-50);
  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-text-50);
  }
  .button-badge {
    border: 1px solid var(--color-text-50) !important;
    > div {
      color: var(--color-text-50) !important;
    }
  }
  &:hover {
    background: var(--color-text-500);
    color: var(--color-text-50) !important;

    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-text-50) !important;
    }
  }
  &:focus {
    background: var(--color-text-600) !important;
    color: var(--color-text-50) !important;
    border-color: var(--color-text-400) !important;
  }

  &:disabled {
    background: var(--color-status-disabled-t15) !important;
  }
}

.button_secondary.ant-btn:not(
    .secondary-button-mouseDown
  ).button_status_danger {
  background: var(--color-status-danger-t15) !important;
  border: 1px solid transparent !important;
  color: var(--color-status-danger-base) !important;
  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-danger-base) !important;
  }
  .button-badge {
    border: 1px solid var(--color-status-danger-base) !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }
  &:hover {
    background: var(--color-status-danger-t30) !important;
    color: var(--color-status-danger-base) !important;

    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-danger-base) !important;
    }
  }
  &:focus {
    background: var(--color-status-danger-t15) !important;
    color: var(--color-status-danger-base) !important;
    border: 1px solid var(--color-status-danger-t45) !important;
  }

  &:disabled {
    background: var(--color-status-danger-t15) !important;
    color: var(--color-status-danger-t30) !important;
    .button-badge {
      border: 1px solid var(--color-status-danger-base) !important;
      > div {
        color: var(--color-status-danger-base) !important;
      }
    }
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-danger-base) !important;
    }
  }
}
.button_secondary.ant-btn:not(
    .secondary-button-mouseDown
  ).button_status_success {
  background: var(--color-status-success-t15) !important;
  border: 1px solid transparent !important;
  color: var(--color-status-success-base) !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-success-base) !important;
  }
  .button-badge {
    background: transparent !important;
    border: 1px solid var(--color-status-success-base) !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }
  &:hover {
    background: var(--color-status-success-t30) !important;
    color: var(--color-status-success-base) !important;

    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-success-base) !important;
    }
  }
  &:focus {
    background: var(--color-status-success-t15) !important;
    color: var(--color-status-success-base) !important;
    border: 1px solid var(--color-status-success-t45) !important;
  }

  &:disabled {
    background: var(--color-status-success-t15) !important;
    color: var(--color-status-success-t30) !important;
    .button-badge {
      border: 1px solid var(--color-status-success-base) !important;
      > div {
        color: var(--color-status-success-base) !important;
      }
    }
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-success-base) !important;
    }
  }
}

.button_secondary.ant-btn.button_status_success {
  .button-spinner > div {
    border: 3px solid var(--color-status-success-base) !important;
    border-color: var(--color-status-success-base) transparent transparent
      transparent !important;
  }
}
.button_secondary.ant-btn:not(
    .secondary-button-mouseDown
  ).button_status_warning {
  background: var(--color-status-warning-t15) !important;
  border: 1px solid transparent !important;
  color: var(--color-status-warning-base) !important;
  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-warning-base) !important;
  }
  .button-badge {
    background: transparent !important;
    border: 1px solid var(--color-status-warning-base) !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }
  &:hover {
    background: var(--color-status-warning-t30) !important;
    color: var(--color-status-warning-base) !important;

    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-warning-base) !important;
    }
  }
  &:focus {
    background: var(--color-status-warning-t15) !important;
    color: var(--color-status-warning-base) !important;
    border: 1px solid var(--color-status-warning-t45) !important;
  }

  &:disabled {
    background: var(--color-status-warning-t15) !important;
    color: var(--color-status-warning-t30) !important;
    .button-badge {
      border: 1px solid var(--color-status-warning-base) !important;
      > div {
        color: var(--color-status-warning-base) !important;
      }
    }
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-warning-base) !important;
    }
  }
}

.button_tertiary.ant-btn:not(.tertiary-button-mouseDown).button_status_default {
  background: transparent !important;
  border: 1px solid var(--color-text-50) !important;
  color: var(--color-text-50) !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-default-base) !important;
    }
  }
  &:hover {
    background: transparent !important;
    border-color: var(--color-primary-base) !important;
    color: var(--color-primary-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-primary-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-primary-base) !important;
      border-color: var(--color-primary-base) transparent transparent
        transparent !important;
    }
  }

  &:focus {
    background: var(--color-text-600) !important;
    border-color: var(--color-text-50) !important;
    color: var(--color-text-50) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-text-50) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-text-50) !important;
      border-color: var(--color-text-50) transparent transparent transparent !important;
    }
  }

  &:disabled {
    background: transparent !important;
    border-color: var(--color-status-disabled-base) !important;
  }
}
.button_tertiary.ant-btn:not(.tertiary-button-mouseDown).button_status_danger {
  background: transparent !important;
  border: 1px solid var(--color-status-danger-base) !important;
  color: var(--color-status-danger-base) !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }

  &:hover {
    background: transparent !important;
    background: var(--color-status-danger-t15) !important;
    border-color: var(--color-status-danger-base) !important;
    color: var(--color-status-danger-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-danger-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-danger-base) !important;
      border-color: var(--color-status-danger-base) transparent transparent
        transparent !important;
    }
  }

  &:focus {
    background: var(--color-status-danger-t15) !important;
    border-color: var(--color-status-danger-t45) !important;
    color: var(--color-status-danger-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-danger-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-danger-base) !important;
      border-color: var(--color-status-danger-base) transparent transparent
        transparent !important;
    }
  }

  &:disabled {
    background: transparent !important;
    border-color: var(--color-status-danger-t30) !important;
    color: var(--color-status-danger-t30) !important;
  }
}
.button_tertiary.ant-btn:not(.tertiary-button-mouseDown).button_status_success {
  background: transparent !important;
  border: 1px solid var(--color-status-success-base) !important;
  color: var(--color-status-success-base) !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }

  &:hover {
    background: transparent !important;
    background: var(--color-status-success-t15) !important;
    border-color: var(--color-status-success-base) !important;
    color: var(--color-status-success-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-success-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-success-base) !important;
      border-color: var(--color-status-success-base) transparent transparent
        transparent !important;
    }
  }

  &:focus {
    background: var(--color-status-success-t15) !important;
    border-color: var(--color-status-success-t45) !important;
    color: var(--color-status-success-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-success-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-success-base) !important;
      border-color: var(--color-status-success-base) transparent transparent
        transparent !important;
    }
  }

  &:disabled {
    background: transparent !important;
    border-color: var(--color-status-success-t30) !important;
    color: var(--color-status-success-t30) !important;
  }
}
.button_tertiary.ant-btn:not(.tertiary-button-mouseDown).button_status_warning {
  background: transparent !important;
  border: 1px solid var(--color-status-warning-base) !important;
  color: var(--color-status-warning-base) !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }

  &:hover {
    background: transparent !important;
    background: var(--color-status-warning-t15) !important;
    border-color: var(--color-status-warning-base) !important;
    color: var(--color-status-warning-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-warning-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-warning-base) !important;
      border-color: var(--color-status-warning-base) transparent transparent
        transparent !important;
    }
  }

  &:focus {
    background: var(--color-status-warning-t15) !important;
    border-color: var(--color-status-warning-t45) !important;
    color: var(--color-status-warning-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-warning-base) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-warning-base) !important;
      border-color: var(--color-status-warning-base) transparent transparent
        transparent !important;
    }
  }

  &:disabled {
    background: transparent !important;
    border-color: var(--color-status-warning-t30) !important;
    color: var(--color-status-warning-t30) !important;
  }
}

.button_danger.ant-btn:not(.danger-button-mouseDown) {
  background: var(--color-status-danger-base) !important;
  border: none !important;
  color: var(--color-white) !important;
  &:hover {
    background: var(--color-status-danger-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-white) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-white) !important;
      border-color: var(--color-white) transparent transparent transparent !important;
    }
  }

  &:focus {
    background: var(--color-status-danger-base) !important;
    border: 2px solid var(--color-status-danger--40) !important;
    color: var(--color-white) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-white) !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-white) !important;
      border-color: var(--color-white) transparent transparent transparent !important;
    }
  }

  &:disabled {
    background: transparent !important;
    border-color: var(--color-status-disabled-base) !important;
  }
}

.button_link.ant-btn:not(.link-button-mouseDown).button_status_default {
  background: transparent !important;
  color: var(--color-primary-base) !important;
  border: 1px solid transparent !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-primary-base) !important;
  }

  &:hover {
    background: var(--color-primary-t15) !important;
  }

  &:focus {
    background: transparent !important;
    border-color: var(--color-primary-t30) !important;
  }

  &:disabled {
    background-color: transparent !important;
    .button-badge {
      border-color: var(--color-text-50) !important;
      > p {
        color: var(--color-text-50) !important;
      }
    }
  }
}
.button_link.ant-btn:not(.link-button-mouseDown).button_status_danger {
  background: transparent !important;
  color: var(--color-status-danger-base) !important;
  border: 1px solid transparent !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-danger-t15) !important;
  }

  &:hover {
    background: var(--color-status-danger-t15) !important;
  }

  &:focus {
    background: transparent !important;
    border-color: var(--color-status-danger-t30) !important;
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--color-status-danger-t30) !important;
    .button-badge {
      > p {
        color: var(--color-status-danger-t30) !important;
      }
    }
  }
}
.button_link.ant-btn:not(.link-button-mouseDown).button_status_success {
  background: transparent !important;
  color: var(--color-status-success-base) !important;
  border: 1px solid transparent !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-success-t15) !important;
  }

  &:hover {
    background: var(--color-status-success-t15) !important;
  }

  &:focus {
    background: transparent !important;
    border-color: var(--color-status-success-t30) !important;
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--color-status-success-t30) !important;
    .button-badge {
      > p {
        color: var(--color-status-success-t30) !important;
      }
    }
  }
}
.button_link.ant-btn:not(.link-button-mouseDown).button_status_warning {
  background: transparent !important;
  color: var(--color-status-warning-base) !important;
  border: 1px solid transparent !important;

  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-warning-t15) !important;
  }

  &:hover {
    background: var(--color-status-warning-t15) !important;
  }

  &:focus {
    background: transparent !important;
    border-color: var(--color-status-warning-t30) !important;
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--color-status-warning-t30) !important;
    .button-badge {
      > p {
        color: var(--color-status-warning-t30) !important;
      }
    }
  }
}

.button_secondary.ant-btn:not(.secondary-button-mouseDown),
.button_tertiary.ant-btn:not(.tertiary-button-mouseDown),
.button_link.ant-btn:not(.link-button-mouseDown) {
  &:disabled {
    color: var(--color-status-disabled-base) !important;
    .button-leftIcon,
    .button-rightIcon {
      color: var(--color-status-disabled-base) !important;
    }
    .button-badge {
      opacity: 0.3 !important;
    }
    .button-spinner > div {
      border: 3px solid var(--color-status-disabled-base) !important;
      border-color: var(--color-status-disabled-base) transparent transparent
        transparent !important;
    }
  }
}

.primary-button-mouseDown.button_status_default {
  background: var(--color-primary-darkest) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }
}
.primary-button-mouseDown.button_status_danger {
  background: var(--color-status-danger--40) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }
}
.primary-button-mouseDown.button_status_success {
  background: var(--color-status-success--40) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }
  .button-badge {
    > div {
      color: var(--color-status-success-base) !important;
    }
  }
}
.primary-button-mouseDown.button_status_warning {
  background: var(--color-status-warning--40) !important;
  color: var(--color-white) !important;
  border: 1px solid transparent !important;
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-white) !important;
  }
  .button-badge {
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }
}

.secondary-button-mouseDown.button_status_default {
  background: var(--color-text-400) !important;
  color: var(--color-text-50) !important;
  border: 1px solid transparent !important;
  .button-badge {
    border: 1px solid var(--color-text-50) !important;
    > div {
      color: var(--color-text-50) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-text-50) !important;
  }
}
.secondary-button-mouseDown.button_status_danger {
  background: var(--color-status-danger-t45) !important;
  color: var(--color-status-danger-base) !important;
  border: 1px solid transparent !important;
  .button-badge {
    border: 1px solid var(--color-status-danger-base) !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-danger-base) !important;
  }
}
.secondary-button-mouseDown.button_status_success {
  background: var(--color-status-success-t45) !important;
  color: var(--color-status-success-base) !important;
  border: 1px solid transparent !important;
  .button-badge {
    background: transparent !important;
    border: 1px solid var(--color-status-success-base) !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-success-base) !important;
  }
}
.secondary-button-mouseDown.button_status_warning {
  background: var(--color-status-warning-t45) !important;
  color: var(--color-status-warning-base) !important;
  border: 1px solid transparent !important;
  .button-badge {
    background: transparent !important;
    border: 1px solid var(--color-status-warning-base) !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-warning-base) !important;
  }
}

.tertiary-button-mouseDown.button_status_default {
  color: var(--color-primary-base) !important;
  background: var(--color-primary-t15) !important;
  border: 1px solid var(--color-primary-base) !important;
  .button-badge {
    border: 1px solid var(--color-primary-base) !important;
    > div {
      color: var(--color-primary-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-primary-base) !important;
  }
  .button-spinner > div {
    border: 3px solid var(--color-primary-base) !important;
    border-color: var(--color-primary-base) transparent transparent transparent !important;
  }
}
.tertiary-button-mouseDown.button_status_danger {
  color: var(--color-status-danger-base) !important;
  background: var(--color-status-danger-t30) !important;
  border: 1px solid var(--color-status-danger-base) !important;
  .button-badge {
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-danger-base) !important;
  }
  .button-spinner > div {
    border: 3px solid var(--color-status-danger-base) !important;
    border-color: var(--color-status-danger-base) transparent transparent
      transparent !important;
  }
}
.tertiary-button-mouseDown.button_status_success {
  color: var(--color-status-success-base) !important;
  background: var(--color-status-success-t30) !important;
  border: 1px solid var(--color-status-success-base) !important;
  .button-badge {
    border: 1px solid transparent !important;
    background-color: var(--color-background-04) !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-success-base) !important;
  }
  .button-spinner > div {
    border: 3px solid var(--color-status-success-base) !important;
    border-color: var(--color-status-success-base) transparent transparent
      transparent !important;
  }
}
.tertiary-button-mouseDown.button_status_warning {
  color: var(--color-status-warning-base) !important;
  background: var(--color-status-warning-t30) !important;
  border: 1px solid var(--color-status-warning-base) !important;
  .button-badge {
    border: 1px solid transparent !important;
    background-color: var(--color-background-04) !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }
  .button-rightIcon,
  .button-leftIcon {
    color: var(--color-status-warning-base) !important;
  }
  .button-spinner > div {
    border: 3px solid var(--color-status-warning-base) !important;
    border-color: var(--color-status-warning-base) transparent transparent
      transparent !important;
  }
}

.link-button-mouseDown.button_status_default {
  background-color: var(--color-status-primary-t30) !important;
  color: var(--color-primary-base) !important;
  border: 1px solid transparent !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-primary-base) !important;
  }
}
.link-button-mouseDown.button_status_danger {
  background-color: var(--color-status-danger-t30) !important;
  color: var(--color-status-danger-base) !important;
  border-color: transparent !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-danger-base) !important;
  }
  .button-badge {
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-danger-base) !important;
    }
  }
}
.link-button-mouseDown.button_status_success {
  background-color: var(--color-status-success-t30) !important;
  color: var(--color-status-success-base) !important;
  border-color: transparent !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-success-base) !important;
  }
  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-success-base) !important;
    }
  }
}
.link-button-mouseDown.button_status_warning {
  background-color: var(--color-status-warning-t30) !important;
  color: var(--color-status-warning-base) !important;
  border-color: transparent !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-status-warning-base) !important;
  }
  .button-badge {
    background-color: var(--color-background-04) !important;
    border: 1px solid transparent !important;
    > div {
      color: var(--color-status-warning-base) !important;
    }
  }
}

.link-button-mouseDown {
  background-color: var(--color-primary-t45) !important;
  color: var(--color-primary-base) !important;

  .button-leftIcon,
  .button-rightIcon {
    color: var(--color-primary-base) !important;
  }
}

.ant-btn {
  display: flex !important;
  box-shadow: none !important;
  &:disabled {
    opacity: 1 !important;
  }
}

.spinnerAlone {
  margin: auto !important;
}

.button-leftIcon,
.button-rightIcon {
  transition: color 0.3s ease;
}
.button-spinner > div {
  transition: border-color 0.3s ease;
}
