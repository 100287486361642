.footer-button {
  display: flex;
  gap: 10px;
}

.margin-balance {
  margin-top: 12px;
}

.Left-Icon {
  z-index: 1;
}
