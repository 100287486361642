.Pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px !important;
  height: 51px;

  padding: 10px 18px;

  background: var(--color-background-01);
  border: 1px solid var(--color-background-04);
  border-top: none;

  border-radius: 0 0 8px 8px;

  p {
    margin-right: 8px;
  }

  .start {
    display: flex;
    align-items: center;
    justify-content: center;

    .page-size-text {
      margin-right: 5px;
    }

    .select-size {
      width: 53px !important;
    }
    .count {
      margin-left: 10px;
    }
  }

  .end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}
