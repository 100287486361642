.Switch {
	display: flex;

	> button {
		width: 35px !important;
		height: 20px !important;

		min-width: none !important;

		> div {
			height: 14px;
			width: 14px;

			top: 3px;
			left: 3px;
		}
	}

	p {
		margin-left: 16px;
	}
}
