.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .input-container {
    position: relative;
    width: 100%;
    height: 36px;

    .TextInput {
      height: 100% !important;
      background-color: var(--color-background-01);
      box-shadow: none !important;
      border: 1px solid var(--color-background-06) !important;
      border-radius: 8px !important;

      font-family: var(--font-family) !important;
      font-size: var(--font-size-xs) !important;
      line-height: var(--font-line-height-xxs) !important;
      font-weight: normal;
      color: var(--color-text-50) !important;

      &::placeholder {
        color: var(--color-text-400) !important;
      }

      &:focus {
        border: 1px solid var(--color-primary-base) !important;
      }

      &:focus-within {
        border: 1px solid var(--color-primary-base) !important;
      }

      &:disabled {
        background-color: var(--color-status-disabled-t15) !important;
      }
    }
  }

  .with-right-icon {
    .TextInput {
      padding-right: 0;
      width: 50;

      &:focus {
        border: 1px solid var(--color-primary-base) !important;
      }
    }
  }
}

.addon-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
