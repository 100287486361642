.footer-button {
  display: flex;
  gap: 10px;
}

.avatar-list {
  display: flex;
  > div:first-child {
    margin-right: 10px;
  }
}
