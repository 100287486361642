.footer-buttom {
  display: flex;

  width: 100%;
  margin-top: 28px;
  padding-top: 16px;
  border-top: 1px solid var(--color-background-04);
}

.edit-footer-buttom {
  margin-top: 16px;
}
