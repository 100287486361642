.header {
  width: 390px;

  padding: 15px 15px 0 15px;

  border: 1px solid var(--color-background-04);
  border-radius: 8px 8px 0 0;
  border-bottom: none !important;

  .text {
    margin-bottom: 8px;
    margin-left: 6px;
  }

  .separator {
    width: 100%;
    height: 1px;

    background-color: var(--color-background-04);
  }
}

.content {
  width: 390px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  border: 1px solid var(--color-background-04);
  border-radius: 0 0 8px 8px;
  border-top: none !important;

  padding: 7px 15px;
  .SectorItem {
    display: flex;
    align-items: center;
    width: 48%;
    max-height: 52px;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    max-width: 170px;
    word-break: normal;
    margin-bottom: 8px;
    justify-content: space-between;

    &:hover {
      background-color: var(--color-text-600);
    }
  }
}

.btn-color {
  color: var(--color-white) !important;
  justify-content: start !important;

  > div:nth-last-child(2) {
    width: 100% !important;
  }

  i {
    color: var(--color-white) !important;
  }
}

.skeleton {
  width: 100%;
  height: 45px;
  > div {
    height: 45px;
  }
}
