.Icon {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.SS {
  height: 7px;
  width: 7px;
  font-size: 7px;
}

.SG {
  height: 9px;
  width: 9px;
  font-size: 9px;
}

.Ss {
  height: 10px;
  width: 10px;
  font-size: 10px;
}

.S {
  height: 12px;
  width: 12px;
  font-size: 12px;
}

.SM {
  height: 14px;
  width: 14px;
  font-size: 14px;
}

.M {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.ML {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.L {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.XXL {
  height: 24px;
  width: 24px;
  font-size: 24px;
}
.XXLL {
  height: 40px;
  width: 40px;
  font-size: 40px;
}

.XXX {
  height: 62px;
  width: auto;
  font-size: 62px;
}
