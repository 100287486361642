.divider {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: var(--color-background-04);
}

.static-option {
  padding: 8px 24px 10px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .text {
    margin-left: 16px;
    color: var(--color-text-200);
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  &:hover {
    background-color: var(--color-background-03);
  }
}
