.component-input-container {
  margin-bottom: 12px;
  padding-left: 20px;

  width: calc(100% - 76px);

  position: relative;

  .order-button {
    display: none;
  }

  &:hover .order-button {
    display: flex !important;

    position: absolute;

    left: 6px;
    top: 11px;
  }

  .component,
  .amount {
    padding-right: 6px;
  }
}

.actions-button {
  margin-left: 6px;
  > div:first-child {
    margin-right: 4px;
  }
}

.cancel-edit-button {
  margin-left: 6px;
}
