.showTechnicalCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

.hideTechnicalCard {
  display: block;
  & > :nth-child(2) {
    display: none;
  }
}

.footer-button {
  display: flex;
  justify-content: space-between;
  > button:first-child {
    margin-right: 10px;
  }
  > div {
    display: flex;
  }

  padding-bottom: 10px;
}
