.body {
  padding: 20px;
}

.no-data-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
}

.cards {
  max-height: 350px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 7px;
  margin-right: -12px;
}
