.Header {
    position: relative;
    width: 100%;
    height: 36px;

    display: flex;
    align-items: center;

    .chevron {
        margin-left: 12px;
        transition: transform 0.5s;
    }

    .icon {
        margin-left: 16px;

        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }

    p {
        margin: 0px;
        margin-left: 16px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        display: flex;
        align-items: center;

        color: var(--color-text-50);
    }

    .permissions {
        position: absolute;
        right: 32px;

        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: var(--color-text-400);
    }
}
