.before-select {
  width: 100%;
  padding-left: 6px;
  align-items: center;

  > div:first-child {
    width: 17px;
    justify-content: center;
    margin-right: 2px;
  }
}
