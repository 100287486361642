.productRow {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 6px 0;
  border-radius: 6px;
  &:hover {
    background-color: var(--color-background-02);
  }
}

.selectedRow {
  background-color: var(--color-background-02);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: -8px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
  }
}

.alignVerticalContent {
  display: flex;
  align-items: center;
  div {
    margin: 0;
    width: 100%;
  }
}

.productMainInfo {
  display: flex;
  gap: 8px;
}

.textContainer {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 12px;
  }
}

.productDescription {
  font-weight: bold;
  display: inline;
}

.productQuantity {
  display: inline;
}

.hideInformation {
  display: none;
}
