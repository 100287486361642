.headerItem {
  font-size: 15px;
  font-weight: bold;

  span {
    color: var(--color-status-danger-base);
  }
}

.headerRow {
  margin-bottom: 12px;
}

.showTechnicalCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

.hideTechnicalCard {
  display: block;
  & > :nth-child(2) {
    display: none;
  }
}
