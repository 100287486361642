.container {
  width: 350px;

  margin-top: 25px;

  > div {
    margin-bottom: 15px !important;
  }
  .button {
    margin-top: 10px;
  }
}

.place-and-time {
  display: block !important;
}
