//card header
.collapse > div > div:first-child {
  background: transparent !important;
  border-radius: 4px !important;

  height: 26px;

  width: fit-content;

  > div {
    padding: 0 6px !important;
  }

  &:hover {
    background: var(--color-text-600) !important;
  }
}

.collapse {
  background: transparent !important;
  border: none !important;

  > div {
    border: none !important;

    > div:last-child {
      border: none !important;

      .body {
        margin-top: 12px !important;
      }
    }
  }

  .title {
    margin-right: 6px;
    user-select: none;
  }
}
